import React from 'react';

import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const colors = {
  primary: '#307fc1',
  grey: '#7d7c7c',
  black: '#000',
};

const activeStyles = {
  borderBottom: `2px solid ${colors.primary}`,
  '& span': {
    color: colors.primary,
    fontSize: '12px',
  },
  '& .icon-img-light': {
    display: 'inline',
  },
};

const styles = {
  textIconButton: {
    fontFamily: 'Rubik',
    padding: '0 13px 8px 13px',
    fontSize: '1.6rem',
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '& .icon-img': {
      display: 'inline-block',
    },
    '& span': {
      color: colors.black,
      position: 'relative',
      textTransform: 'initial',
      fontSize: '12px',
    },
    '& img': {
      position: 'relative',
    },
  },
  active: activeStyles
};

const TextIconButton = withStyles(styles)(
  ({ classes, text, icon, active, iconActive }) => (
    <button
      size="medium"
      className={clsx(classes.textIconButton, active && classes.active)}
    >
      <img className={'icon-img'} src={active ? iconActive : icon} alt={''} />
      <br />
      <span className={classes.textLabel}>{text}</span>
    </button>
  ),
);

export default TextIconButton;
