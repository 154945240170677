import { useCallback } from 'react'
import { useQuery, useMutation } from 'react-query'
import config from 'config'
import { useAuthState, useAxios, encodeQueryParams } from 'packages/core'
import useApi from './useApi'
import { useApiGetWithInfiniteScroll } from 'packages/core'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'packages/eid-ui'
import useApiWithPagination from './useApiWithPagination'
import { useCurrentPerson } from './personHooks'
export * from './sessionTimeoutHooks'

export const useListingData = (url, enabled = true) => {
    const [state] = usePageContext()
    const { searchKey, sortBy, sortOrder } = state

    let queryParams = {}

    if (state['advancedSearch.forms.startDate']) {
        queryParams.startDate = state['advancedSearch.forms.startDate']
    }
    if (state['advancedSearch.forms.endDate']) {
        queryParams.endDate = state['advancedSearch.forms.endDate']
    }
    if (state['advancedSearch.forms.delegationType']) {
        queryParams.delegationTypeId =
            state['advancedSearch.forms.delegationType'].id
    }

    if (state['advancedSearch.forms.delegatee']) {
        queryParams.delegateeId = state['advancedSearch.forms.delegatee'].id
    }
    if (state['advancedSearch.forms.delegator']) {
        queryParams.delegatorId = state['advancedSearch.forms.delegator'].id
    }

    let queryString = encodeQueryParams({
        searchTerm: searchKey,
        sortBy: sortBy ? sortBy : '',
        descending: sortBy ? `${sortOrder === 'asc' ? false : true}` : '',
        startDate: queryParams.startDate?.toISOString(),
        endDate: queryParams.endDate?.toISOString(),
        delegateeId: queryParams?.delegateeId,
        delegatorId: queryParams?.delegatorId,
        delegationTypeId: queryParams?.delegationTypeId,
    })

    return useApiGetWithInfiniteScroll(url, queryString, enabled)
}

export const useDelegationsListingData = (url) => {
    const [state] = usePageContext()
    const callApi = useAxios()

    const { searchKey, sortBy, sortOrder } = state

    let queryParams = {}

    if (state['advancedSearch.forms.startDate']) {
        queryParams.startDate = state['advancedSearch.forms.startDate']
    }
    if (state['advancedSearch.forms.endDate']) {
        queryParams.endDate = state['advancedSearch.forms.endDate']
    }
    if (state['advancedSearch.forms.delegationType']) {
        queryParams.delegationTypeId =
            state['advancedSearch.forms.delegationType'].id
    }

    if (state['advancedSearch.forms.delegatee']) {
        queryParams.delegateeId = state['advancedSearch.forms.delegatee'].id
    }
    if (state['advancedSearch.forms.delegator']) {
        queryParams.delegatorId = state['advancedSearch.forms.delegator'].id
    }

    let queryString = encodeQueryParams({
        searchTerm: searchKey,
        sortBy: sortBy ? sortBy : '',
        descending: sortBy ? `${sortOrder === 'asc' ? false : true}` : '',
        startDate: queryParams.startDate?.toISOString(),
        endDate: queryParams.endDate?.toISOString(),
        delegateeId: queryParams?.delegateeId,
        delegatorId: queryParams?.delegatorId,
        delegationTypeId: queryParams?.delegationTypeId,
    })

    return useApiWithPagination(
        [url, queryString],
        (skip, take) => {
            const skipTakeQueryParam = `skip=${skip}&take=${take}`
            const fullUrl =
                url +
                (queryString
                    ? `?${skipTakeQueryParam}&${queryString}`
                    : `?${skipTakeQueryParam}`)

            return callApi({
                url: fullUrl,
            })
        },
        {
            enabled: Boolean(url),
        },
    )
}
export const useDelegationTypes = () => {
    const callApi = useAxios()
    return useQuery(
        ['Delegation_Types'],

        () =>
            callApi({
                method: 'GET',
                url: `/api/v1/Delegation/types`,
            }).then((data) => data.data),
    )
}

export const useCreateDelegation = () => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } = useDelegationsListingData(
        '/api/v1/Delegation/Delegator',
    )

    return useMutation(
        (newDelegation) =>
            callApi({
                method: 'POST',
                url: '/api/v1/Delegation/Create',
                data: newDelegation,
            }),
        {
            onSuccess: (data) => {
                showSuccessMessage(t('DelegationCreatedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('Error'))
            },
        },
    )
}

export const useCreateCollaborationTaskDelegation = () => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } = useDelegationsListingData(
        '/api/v1/Delegation/WorkflowTaskDelegations/Delegator',
    )

    return useMutation(
        (newDelegation) =>
            callApi({
                method: 'POST',
                url: '/api/v1/Delegation/workflowTaskDelegation/create',
                data: newDelegation,
            }),
        {
            onSuccess: (data) => {
                showSuccessMessage(t('DelegationCreatedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('Error'))
            },
        },
    )
}

export const useEditDelegation = (url, revalidateUrl, appendDataToUrl) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } =
        useDelegationsListingData(revalidateUrl)

    return useMutation(
        (data) => {
            if (appendDataToUrl) {
                const startDate = data.startDateUtc?.format()
                const endDate = data.endDateUtc?.format()
                const activeOnlyIfOutOfOffice = Boolean(
                    data.activeOnlyIfOutOfOffice,
                )
                url =
                    url +
                    `/${
                        data.delegateePersonId
                    }?activeOnlyIfOutofOffice=${activeOnlyIfOutOfOffice}${
                        startDate ? `&startDate=${startDate}` : ''
                    }${endDate ? `&endDate=${endDate}` : ''}`
                data = null
            }
            return callApi({
                method: 'PUT',
                url,
                data,
            })
        },
        {
            onSuccess: () => {
                showSuccessMessage(t('DelegationEditedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('Error'))
            },
        },
    )
}

export const useDeleteDelegation = (
    url,
    revalidateUrl,
    appendDelegatorId = false,
) => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { data: currentPerson } = useCurrentPerson()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } =
        useDelegationsListingData(revalidateUrl)

    if (appendDelegatorId) {
        const delegatorId = currentPerson.id
        url = url + `/${delegatorId}`
    }

    return useMutation(
        (delegationIds) =>
            callApi({
                method: 'DELETE',
                url,
                data: delegationIds,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('DelegationDeletedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('Error'))
            },
        },
    )
}

export const useIdentity = (id) => {
    const callApi = useAxios()
    return useQuery(
        ['IDENTITY', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/v1/people/identities/${id}`,
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApiGetOld = (url) => {
    const [{ accessToken }] = useAuthState()
    return useApi(
        useCallback(
            () => ({
                baseURL: config.API_URL,
                method: 'GET',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            [accessToken, url],
        ),
    )
}

export const useApiPost = (url) => {
    const [{ accessToken }] = useAuthState()
    return useApi(
        useCallback(
            (data) => ({
                baseURL: config.API_URL,
                method: 'POST',
                url,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            [accessToken, url],
        ),
    )
}

export const useApiPut = (url, callback) => {
    const [{ accessToken }] = useAuthState()
    return useApi(
        useCallback(
            (data) => ({
                baseURL: config.API_URL,
                method: 'PUT',
                url,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            [accessToken, url],
        ),
        callback,
    )
}

export * from './applicationSettingsHooks'
export * from './authorizationHooks'
export * from './localizationHooks'
export * from './personHooks'
export * from './notificationHooks'
