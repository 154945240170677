import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        color: '#ff5e65 !important',
        backgroundColor: '#ffffff !important',
        border: '1px solid #ff5e65',
        boxShadow: 'none',
        margin: 0,
        minWidth: '65px !important',
        padding: '8px 12px',
        lineHeight: 0,

        '&:hover': {
            color: '#ffffff !important',
            backgroundColor: '#ff5e65 !important',
            borderColor: 'transparent !important',
        },
        ...props.styles,
    }),
    icon: {
        marginLeft: 6,
        color: '#ff5e65',
    },
}))
const DeleteButton = ({ color, styles, children, ...rest }) => {
    const classes = useStyles({ color, styles })
    return (
        <Button
            variant="outlined"
            size="small"
            className={classes.root}
            {...rest}
        >
            {children}
        </Button>
    )
}

export default DeleteButton
