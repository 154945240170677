import React, { useEffect, useState } from 'react'
import { Box, Grid, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Divider } from 'packages/eid-ui'
import GenericCard from './Cards/GenericCard'
import GenericCardContent from './Cards/GenericCardContent'
import CardFieldGroup from './Cards/CardFieldGroup'
import CardField from './Cards/CardField'
import { DefaultAvatarIcon } from 'packages/eid-icons'
import ChangePhotoIcon from 'icons/icon-change-photo.svg'
import ProfilePictureModal from './ProfilePictureModal'
import { useApiPost } from 'hooks'
import { OrgChartIcon } from 'packages/eid-icons'
import { history } from 'packages/core'
import appConfig from 'config'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    title: {
        fontSize: '24px',
        fontWeight: 'normal',
    },
    boldText: { fontWeight: 'bold', fontSize: '24px' },
    overlayWrapper: {
        width: '195px',
        height: '195px',
        marginBottom: 30,
        marginTop: '2em',
        position: 'relative',
    },
    bigAvatar: {
        // margin: 'auto', // is messing up things in IE
        width: '195px',
        height: '195px',
        '&:hover': {
            '& + span': {
                display: 'flex',
            },
        },
    },
    editOverlay: {
        display: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderRadius: '50%',
        top: 0,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            display: 'flex',
        },
    },
})

const BasicInfoCard = (props) => {
    const { t } = useTranslation()
    const fields = [
        {
            name: 'avatar',
            type: 'AvatarImage',
            label: t('KeepYourProfileUpdated'),
            description: t('ChangePhotoHelpText'),
            required: false,
        },
    ]

    const { basicInfo, personGuid, config } = props
    const classes = useStyles()
    const [modalData, setModalData] = React.useState({
        open: false,
        section: '',
    })
    const [localImage, setLocalImage] = React.useState('')
    const [showLocalImage, setShowLocalImage] = React.useState(false)
    const [personDetails] = React.useState({
        basicInfo: {
            avatar: DefaultAvatarIcon,
        },
    })
    const [loadingData, setLoadingData] = useState(false)
    const [response, setResponse] = useState(undefined)

    const [fetchAPI, setFetchAPI] = useState(true)

    useEffect(() => {
        if (fetchAPI) {
            setFetchAPI(false)
        }
    }, [fetchAPI])

    const handleImageUpdate = (data) => {
        const triggerUpdate = data.avatar !== personDetails.basicInfo.avatar
        if (triggerUpdate) {
            setLocalImage(data.avatar)
            setLoadingData(true)
            const personImage = '"' + data.avatar + '"'
            updateProfilePicture(personImage)
        }
    }

    const [
        { data: updateResponse, error: updateError },
        updateProfilePicture,
    ] = useApiPost(`api/v1/me/image/${personGuid}`)

    useEffect(() => {
        if (updateResponse && updateResponse.isSuccess) {
            setModalData(false)
            setLoadingData(false)
            setShowLocalImage(true)
            setFetchAPI(true)
        } else if (updateError) {
            setResponse({
                success: false,
                message:
                    "Something went wrong. Server couldn't process your data",
            })
        }
    }, [updateResponse, updateError])

    return (
        <GenericCard
            onClick={() =>
                history.push(`${appConfig.APP_SUBPATH}/orgchart/${personGuid}`)
            }
            editable={config.editable}
            helpText={t('ShowOrgChart')}
            editIcon={OrgChartIcon}
            showEditOption={config.showEditOption}
        >
            <Grid container justifyContent="center" alignItems="center">
                <Box className={classes.overlayWrapper}>
                    <Avatar
                        alt="Basic Info Avatar"
                        src={
                            showLocalImage
                                ? localImage
                                : `${appConfig.BASE_EID_URL}${basicInfo.pathToImage}`
                        }
                        className={classes.bigAvatar}
                    />
                    <span
                        className={classes.editOverlay}
                        onClick={() => {
                            setModalData({
                                open: true,
                            })
                            setResponse(undefined)
                        }}
                    >
                        <img src={`${appConfig.APP_SUBPATH}${ChangePhotoIcon}`} alt={''} />
                    </span>
                </Box>
            </Grid>

            <GenericCardContent avatarCard>
                <Box>
                    <CardFieldGroup>
                        <CardField
                            label={t('FirstName')}
                            value={basicInfo.firstName}
                            maxWidth={130}
                            bold
                            variant="h5"
                        />
                        <Divider vertical color="#ffffff" width={23} />
                        <CardField
                            label={t('LastName')}
                            value={basicInfo.lastName}
                            maxWidth={130}
                            variant="h5"
                        />
                    </CardFieldGroup>

                    <CardField
                        label={t('JobTitle')}
                        value={basicInfo.jobTitle}
                        singleField
                    />
                    <CardField
                        label={t('Department')}
                        value={basicInfo.department}
                        singleField
                    />
                </Box>
            </GenericCardContent>

            {modalData.open && (
                <ProfilePictureModal
                    open={modalData.open}
                    onClose={() => setModalData({ section: '', open: false })}
                    header={t('Photo')}
                    fields={fields}
                    loading={loadingData}
                    data={basicInfo}
                    updateData={(data) => handleImageUpdate(data)}
                    actions={[
                        { label: t('ChangePhoto') },
                        { label: t('SavePhoto'), active: true },
                    ]}
                    response={response}
                />
            )}
        </GenericCard>
    );
}

export default BasicInfoCard
