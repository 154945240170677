import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        color: '#307fc1 !important',
        backgroundColor: '#ffffff !important',
        border: '1px solid #307fc1',
        margin: 0,
        boxShadow: 'none',
        minWidth: '50px !important',
        padding: '8px 12px',
        lineHeight: 0,

        '&:hover': {
            color: '#ffffff !important',
            backgroundColor: '#307fc1 !important',
            borderColor: 'transparent !important',
        },
        ...props.styles,
    }),
    icon: {
        color: '#307fc1',
        fill: '#307fc1',
        marginLeft: 6,
    },
}))
const ViewDetailsButton = ({ color, styles, children, ...rest }) => {
    const classes = useStyles({ color, styles })
    return (
        <Button size="small" className={classes.root} {...rest}>
            {children}
        </Button>
    )
}

export default ViewDetailsButton
