import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { useDeleteDelegation } from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import { useModalState } from 'packages/core'

const DeleteDelegateButton = ({ data, attribute }) => {
    const { t } = useTranslation()

    const [modalOpen, openModal, closeModal] = useModalState()

    const { deleteUrl, revalidateUrl, primaryKey, appendDelegatorId } =
        attribute.component

    const [deleteDelegation, { isLoading: isDeleting }] = useDeleteDelegation(
        deleteUrl,
        revalidateUrl,
        appendDelegatorId,
    )

    return (
        <>
            <WarningModal
                title={t('Confirm')}
                description={t('DeleteDelegationConfirmation', {
                    type: data.approvalFlowStepFriendlyName,
                    name: data.delegateeFriendlyName,
                })}
                yesLabel={t('Yes')}
                noLabel={t('No')}
                open={modalOpen}
                cancel={closeModal}
                proceeding={isDeleting}
                proceed={() => {
                    deleteDelegation([data[primaryKey]])
                    closeModal()
                }}
            />

            <Button.Delete onClick={openModal}>
                {t('Delete')}
            </Button.Delete>
        </>
    )
}
export default DeleteDelegateButton
