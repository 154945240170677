import React, { FC, memo } from 'react'
import { Handle, NodeProps, Position } from 'react-flow-renderer'
import { Person } from './Person'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const colors = ['248, 42, 25', '1, 174, 143', '217, 121, 0', '121, 121, 191'];
const getRandomnColor = () => colors[Math.floor(Math.random() * colors.length)]

const useStyles = makeStyles(
    createStyles({
        bottomConnector: (props: any) => ({
            width: '12px',
            height: '12px',
            border: `solid 0.7px ${props.color}`,
            backgroundColor: props.color,
        }),

    })

    ,
)

export const PersonNode: FC<NodeProps> = memo((props) => {
    const colorValue = getRandomnColor()
    const color = `rgb(${colorValue})`

    const classes = useStyles({ color })

    const {
        data: person,
        isConnectable,
        targetPosition = Position.Top,
        sourcePosition = Position.Bottom,
    } = props

    return (
        <>
            {person.parentId && (
                <Handle
                    type="target"
                    position={targetPosition}
                    isConnectable={isConnectable}
                />
            )}
            <Person person={person} colorValue={colorValue} />
            <Handle
                type="source"
                position={sourcePosition}
                isConnectable={isConnectable}
                className={classes.bottomConnector}
            />
        </>
    )
})
