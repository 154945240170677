import React from 'react';

import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const colors = {
  primary: '#307fc1',
  grey: '#7d7c7c',
};

const styles = {
  textIconButton: {
    borderRadius: '18.5px',
    fontFamily: 'Rubik',
    fontSize: '1.6rem',
    textAlign: 'left',
    '&.MuiButton-text': {
      padding: '6px 12px 0px 12px',
    },
    '& .icon-img': {
      display: 'inline',
      marginLeft: '10px',
      marginRight: '12px',
    },
    border: `solid 1.40px ${colors.primary}`,
    '& span': {
      color: colors.primary,
      position: 'relative',
      textTransform: 'initial',
      top: '-3px',
    },
    '& img': {
      position: 'relative',
      left: '8px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: colors.primary,
      boxShadow: '0 5px 5px -2px rgba(48, 127, 193, 0.55)',
      color: 'white',
      '& span': {
        color: '#fff',
      },
      '& .icon-img': {
        display: 'none',
      },
      '& .icon-img-light': {
        display: 'inline',
      },
    },
  },
};

const TextIconButton = withStyles(styles)(
  ({ classes, text, icon, iconActive }) => (
    <Button
      size="medium"
      className={clsx(classes.textIconButton, iconActive && classes.hover)}
    >
      {text}
      <img className={'icon-img'} src={icon} alt={''} />
      {iconActive && (
        <img className={'icon-img-light'} src={iconActive} alt={''} />
      )}
    </Button>
  ),
);

export default TextIconButton;
