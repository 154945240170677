import { Skeleton } from '@mui/material';
import { Box } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    createStyles({
        root: {
            position: 'relative',
            width: 248,
            height: 248,
            margin: "10px",
            padding: "20px",
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 4px 16px 0px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            paddingTop: '80px',
        },
        circle: {
            height: '62px',
            width: '62px',
            backgroundColor: '#bdbdbd',
        },
        avatarContainer: {
            backgroundColor: '#eef0f4',

            width: '82px',
            height: '82px',

            position: 'absolute',
            top: '-41px',
            left: "83px",

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            borderRadius: '50%',

        },
        attributesContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

            '& span': {
                margin: '6px 0px'
            }

        }
    }),
)


export const OrgChartSkeleton = ({ ...rest }: any) => {

    const classes = useStyles()

    const card = <Box
        className={classes.root}
        {...rest}
    >
        <Box className={classes.avatarContainer}>
            <Skeleton
                variant="circular"
                animation='wave'
                className={classes.circle}
            />

        </Box>

        <Box className={classes.attributesContainer}>

            <Skeleton variant="text" animation="wave" height={28} />
            <Skeleton variant="text" animation="wave" height={28} />
            <Skeleton variant="text" animation="wave" height={28} />
        </Box>
    </Box>

    return <Box height='80%' display='flex' width='100%' marginTop='200px' justifyContent='center'>
        <Box width='70%'>
            <Box display='flex' alignItems='center' justifyContent='center' marginBottom='70px'>
                {card}
            </Box>

            <Box display='flex' alignItems='center' justifyContent='space-around'>
                {card}
                {card}
                {card}
            </Box>
        </Box>

    </Box>
}


