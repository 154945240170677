import React from 'react'
import { Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '33px !important',

        color: '#307fc1 !important',
        backgroundColor: '#ffffff !important',
        border: '1px solid #307fc1',
        margin: 0,
        boxShadow: 'none',
        minWidth: '120px !important',
        lineHeight: 0,
        zIndex: 0,

        '&:hover': {
            color: '#ffffff !important',
            backgroundColor: '#307fc1 !important',
            borderColor: 'transparent !important',

            '& svg': {
                fill: '#ffffff !important',
            },
        },
        ...props.styles,
    }),
    icon: {
        fill: '#307fc1 !important',
        marginLeft: 6,
    },
}))
const ViewProfileButton = ({ color, styles, children, ...rest }) => {
    const classes = useStyles({ color, styles })
    return (
        <Fab variant="extended" size="small" className={classes.root} {...rest}>
            {children}
            <Icon
                name="ArrowSmall"
                direction="right"
                className={classes.icon}
            ></Icon>
        </Fab>
    )
}

export default ViewProfileButton
