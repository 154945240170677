import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components'
import { Dropdown } from 'packages/eid-ui'
import CardSortingItem from './CardSortingItem'
import { usePageContext } from 'pageContext'

const SortingList = ({ attributes, primaryProp }) => {
    const sortableAttributes = attributes.filter((x) => x.sort)
    const { t } = useTranslation()

    const [{ sortBy, sortOrder }, dispatch] = usePageContext()

    const handleSort = (heading) => {
        const label = heading.label
        const sortByName = heading[primaryProp]
            ? heading[primaryProp]
            : heading.name
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortLabel: label,
                sortOrder: getSortOrder(sortByName),
            },
        })
    }
    const getSortOrder = (sortByName) => {
        if (sortBy !== sortByName || !sortOrder || sortOrder === 'asc') {
            return 'desc'
        }

        return 'asc'
    }
    return (
        <>
            {sortableAttributes.map((attribute, index) => (
                <CardSortingItem
                    key={`${attribute.name}_${index}`}
                    displayName={t(attribute.label)}
                    onClick={() => handleSort(attribute)}
                    selected={
                        sortBy === attribute.sortBy || attribute.friendlyName
                    }
                    order={sortOrder}
                />
            ))}
        </>
    )
}

const SortingDropdown = React.forwardRef(
    ({ open, setOpen, attributes, primaryProp }, ref) => {
        const handleClose = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                return
            }

            setOpen(false)
        }
        return (
            <Dropdown
                open={open}
                rootStyles={{
                    paddingRight: '0px',
                }}
                width="320px"
                listStyles={{
                    paddingBottom: '0px',
                    '& > ul': {
                        paddingBottom: '7px',
                        paddingTop: '0px',
                    },
                }}
                anchorEl={ref.current}
                anchorOriginPosition={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorTransformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                handleClose={handleClose}
                showCone={false}
                menuHeight="45vh"
            >
                <SortingList
                    attributes={attributes}
                    primaryProp={primaryProp}
                />
            </Dropdown>
        )
    },
)

const CardSortingMenu = ({ attributes, primaryProp }) => {
    const [{ sortLabel, sortOrder }] = usePageContext()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const displayButton = (
        <>
            <Button.Sort
                label={t('OrderBy')}
                subLabel={t(sortLabel)}
                order={sortOrder}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            />
            <span
                ref={anchorRef}
                style={{ marginLeft: '-15px', height: '50px' }}
            />
        </>
    )

    return (
        <>
            {displayButton}
            {open && (
                <SortingDropdown
                    open={open}
                    setOpen={setOpen}
                    attributes={attributes}
                    ref={anchorRef}
                    primaryProp={primaryProp}
                />
            )}
        </>
    )
}

export default CardSortingMenu
