import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Link, styled, useTheme } from '@mui/material'
import { HorizontalTabs, EidButton, ViewIndicator } from 'packages/eid-ui'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useIsSmallScreen } from 'packages/core'
import { useConfiguration } from 'appConfiguration/configurations'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { DropdownMenu } from 'components/DropdownMenu'
import { SubPathLevel } from '../../appConfiguration'
import appConfig from 'config'
import { ITEMS_VIEW, subHeaderHeight } from 'utils'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
        marginTop: '8px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        height: '4px',
        padding: '0 6px',
        boxSizing: 'content-box',
        marginLeft: '-6px',
        marginBottom: '0px',
        zIndex: 1,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
        letterSpacing: '0.022em',
    },
    '& .MuiTab-root': {
        fontSize: '14px',
        letterSpacing: '0.3px',
        margin: '-2px 0px 0 8px',
        transition: 'color 0.15s ease-in-out',

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))

const useStyles = makeStyles(
    createStyles({
        navBarContainer: {
            height: subHeaderHeight,
            marginBottom: '-4px',
            position: 'relative',
            whiteSpace: 'nowrap',

            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                top: '-4px',
                width: '100%',
                height: 'calc(100% - 2px)',
                borderBottom: '1px solid #d8d8dd',
            },

            '&::after': {
                content: '""',
                display: 'block',
                width: '200%',
                height: '16px',
                backgroundImage:
                    'linear-gradient(to top, rgba(238, 240, 244, 0), rgba(238, 240, 244, 0.9))',
                position: 'absolute',
                left: '-50%',
                bottom: '-10px',
            },
        },
        cartMenu: {
            display: 'flex',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
        mobileCartMenu: {
            display: 'flex',
            position: 'absolute',
            top: '25px',
            right: '8px',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
    }),
)

const SubHeader = (props) => {
    const {
        showCardView,
        toggleCardView,
        toggleFilters,
        showFilters,
        showFiltersButton,
        showBackButtonOnly,
        showBackButton,
    } = props

    const theme = useTheme()
    const { t } = useTranslation()
    const { dropDownOptions } = useConfiguration()
    const isSmallScreen = useIsSmallScreen()
    const classes = useStyles()
    const history = useHistory()

    const {
        location: { pathname },
    } = history
    const pathnames = pathname.split('/')
    const selectedOption = dropDownOptions.find(
        (o) =>
            o.route ===
            `${appConfig.APP_SUBPATH}/${pathnames[1 + SubPathLevel]}`,
    )
    let activeTab = history.location.pathname

    return isSmallScreen ? (
        <></>
    ) : (
        <Box
            height={isSmallScreen ? '128px' : subHeaderHeight}
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'start' : 'center'}
            justifyContent="space-between"
            width="100%"
            className={isSmallScreen ? '' : [classes.navBarContainer]}
        >
            {showBackButtonOnly ? (
                <Box width="100%" display="flex" justifyContent="flex-end">
                    <EidButton.BackButton label={t('Back')} />
                </Box>
            ) : (
                <Fragment>
                    <DropdownMenu
                        routeOptions={dropDownOptions}
                        totalCount={5}
                    />

                    {!isSmallScreen &&
                        selectedOption?.tabbedOptions?.length > 0 && (
                            <SubHeaderTabs
                                variant="scrollable"
                                value={activeTab}
                                onChange={(_, value) => history.push(value)}
                            >
                                {selectedOption.tabbedOptions.map((op) => (
                                    <HorizontalTabs.Tab
                                        key={op.name}
                                        value={`${
                                            selectedOption.route + op.route
                                        }`}
                                        label={t(op.title)}
                                    />
                                ))}
                            </SubHeaderTabs>
                        )}

                    {isSmallScreen && showFiltersButton && (
                        <Link
                            component="button"
                            onClick={toggleFilters}
                            style={{ marginRight: '20px', minWidth: '24px' }}
                        >
                            <Icon
                                name="AdvancedSearch"
                                // color="#959598"
                                color={
                                    showFilters
                                        ? theme.palette.primary.main
                                        : '#959598'
                                }
                            />
                        </Link>
                    )}
                    {!isSmallScreen && toggleCardView && (
                        <Box display="flex" justifyContent="flex-end">
                            {showFiltersButton && (
                                <Link
                                    component="button"
                                    onClick={toggleFilters}
                                    style={{
                                        marginRight: '20px',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Icon
                                        name="AdvancedSearch"
                                        // color="#959598"
                                        color={
                                            showFilters
                                                ? theme.palette.primary.main
                                                : '#959598'
                                        }
                                    />
                                </Link>
                            )}

                            <ViewIndicator
                                view={
                                    showCardView
                                        ? ITEMS_VIEW.CARD
                                        : ITEMS_VIEW.LIST
                                }
                                onCardViewClick={() => toggleCardView(true)}
                                onListViewClick={() => toggleCardView(false)}
                                cardViewLabel={t('Card_View_Label')}
                                listViewLabel={t('List_View_Label')}
                            />
                        </Box>
                    )}

                    {showBackButton && (
                        <EidButton.BackButton label={t('BackToWorkflows')} />
                    )}
                </Fragment>
            )}
        </Box>
    )
}

export default SubHeader
