import React, { useState } from 'react'
import { createStyles, withStyles } from '@mui/styles'
import {
    Box,
    InputBase,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const options = [
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Yearly', label: 'Yearly' },
]

const StyledInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            border: 'solid 1px #ebebed',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            minWidth: '64px',

            '&:focus': {
                borderRadius: 4,
                borderColor: 'transparent',
                boxShadow: '0 0 0 0.3rem rgba(0,123,255,.25)',
            },
        },
    }),
)(InputBase)

export const TypeSelector = (props: any) => {
    const { t } = useTranslation()

    const { handleChange, value, loading }: any = props

    const [open, setOpen] = useState(false)

    const handleClose = (event: any) => {
        setOpen(false)
    }

    const handleOpen = (event: any) => {
        setOpen(true)
    }

    const onChange = (event: SelectChangeEvent<string>) => {
        handleChange(event.target.value)
    }

    const menuProps: any = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }

    return (
        <>
            {loading ? (
                <Box width="100% " display="flex" justifyContent="center">
                    <Loader margin="auto" height="24px" />
                </Box>
            ) : (
                <>
                    <Select
                        variant="standard"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={value}
                        onChange={onChange}
                        MenuProps={{
                            ...menuProps,
                        }}
                        input={<StyledInput />}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            )}
        </>
    )
}
