import React from 'react'
import { Box } from '@mui/material'
import GenericFilter from '../GenericFilter'
import { usePageContext } from 'pageContext'

const defaultValues = {
    text: '',
    checkbox: false,
    autocomplete: null,
    tree: null,
    checklist: [],
}

const FormsFilter = ({ filters }) => {
    const [state, dispatch] = usePageContext()

    return (
        <>
            {filters.map((f) => (
                <Box key={f.name} padding="20px 20px 0px 20px">
                    <GenericFilter
                        value={state[f.contextProp] ?? defaultValues[f.type]}
                        onChange={(val) => {
                            dispatch({
                                type: 'SET_PROP',
                                payload: {
                                    key: f.contextProp,
                                    value: val,
                                },
                            })
                        }}
                        {...f}
                    />
                </Box>
            ))}
        </>
    )
}

export default FormsFilter
