import CreateNewDelegateButton from './CreateNewDelegateButton'

const CreateNewCollaborationTasksDelegateButton = () => {
    return (
        <CreateNewDelegateButton
            label="CreateCollaborationTaskDelegation"
            title="DelegateAllCollaborationTasksModalTitle"
            isCollaborationTask={true}
        />
    )
}

export default CreateNewCollaborationTasksDelegateButton
