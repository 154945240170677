import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { MyIdentityLayout, SubHeader } from 'components'
import BasicInfoCard from './BasicInfoCard'
import GeneralCard from './GeneralCard'
import OrganizationCard from './OrganizationCard'
import ContactCard from './ContactCard'
import LocationCard from './LocationCard'
import {
    useApiPut,
    useCurrentPerson,
    useEidGlobalSettings,
    useIdentity,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Icon } from 'packages/eid-icons'
import EditModal from './EditModal'
import { useIdentityConfig } from './data'
import appConfig from 'config'
import CustomField from 'components/CustomField'
import { WarningModal } from 'packages/eid-ui'
import OutOfOfficeCard from './outofofficecard'
import OutOfOfficeModal from './OutOfOfficeModal'
import { useQuery } from 'packages/core'
import { useConfiguration } from 'appConfiguration'
import moment from 'moment'

const Item = ({ children }) => (
    <Box sx={{ padding: '0 7.5px 0 0', marginBottom: '40px' }}>{children}</Box>
)

const firstWrap = 960
const secondWrap = 760

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        [theme.breakpoints.down(secondWrap)]: {
            flexDirection: 'column',
        },
    },
    leftColumn: {
        flex: '30%',
        [theme.breakpoints.down(firstWrap)]: {
            flex: '50%',
        },
    },
    rightColumn: {
        flex: '70%',
        [theme.breakpoints.down(firstWrap)]: {
            flex: '50%',
        },
    },
    rightColumns: {
        width: '50%',
        [theme.breakpoints.down(firstWrap)]: {
            width: '100%',
        },
    },
    editProfileButton: {
        backgroundColor: '#307fc1',
        color: '#fff',
        height: '50px',
        borderRadius: '6px',
        marginBottom: '16px',
        paddingLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
}))

const sectionDisplayNames = {
    basicInfo: 'BasicInfo',
    generalInfo: 'General',
    orgInfo: 'Organization',
    contactInfo: 'Contact',
    locationInfo: 'Location',
}

const outOfOfficeCardControlName =
    'MyIdentity-ViewProfile-OutOfOfficeCard-Control'

const IdentityDetails = ({ personGuid }) => {
    const [personDetails, setPersonDetails] = useState(null)
    const [personDetailsSource, setPersonDetailsSource] = useState(null)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [response, setResponse] = useState(undefined)
    const [showOutOfOfficeModal, setShowOutOfOfficeModal] = useState(false)

    const { data, error } = useIdentity(personGuid)
    const { data: loggedInPerson } = useCurrentPerson()
    const { data: globalSettings } = useEidGlobalSettings()

    const isSelf = loggedInPerson.id === personGuid
    const config = useIdentityConfig(isSelf)

    const {
        canSeeManageYourIdentityButton,
        canSeeManageSomeoneElseIdentityButton,
        hasAccessToControl,
    } = useConfiguration()

    const hasAccessToOutOfOfficeCard = hasAccessToControl(
        outOfOfficeCardControlName,
    )

    const hideManageIdentityWorkflowButton =
        globalSettings?.find(
            (x) => x.name === 'MyID_Hide_Edit_Button_Via_Workflow',
        )?.value === 'true'

    const location = useLocation()

    const { t } = useTranslation()

    const history = useHistory()
    const query = useQuery()

    useEffect(() => {
        if (data) {
            const res = data.data
            setPersonDetails({
                ...res,
                manager:
                    res.managerFirstName || res.managerLastName
                        ? `${res.managerFirstName} ${res.managerLastName}`
                        : null,
            })

            //keep static copy of orginal details
            setPersonDetailsSource({
                ...res,
                manager:
                    res.managerFirstName || res.managerLastName
                        ? `${res.managerFirstName} ${res.managerLastName}`
                        : null,
            })
        }
    }, [data])

    useEffect(() => {
        if (error && error.message === '401') {
            window.location.reload()
        }
    }, [error])

    const [updateApi, setUpdateApi] = useState(false)
    const [{ data: updateResponse, error: updateError }, updatePerson] =
        useApiPut(`/api/v1/me/identities/${personGuid}`)

    useEffect(() => {
        if (updateResponse) {
            if (
                updateResponse.data &&
                updateResponse.data.personUpdatedSuccessfully
            ) {
                setShowWarningModal(false)
                setUnsavedChanges(false)
                setResponse({
                    success: true,
                    message: 'Successfully Updated',
                })
                setPersonDetails({
                    ...personDetails,
                    ...modalData.data,
                })
                setLoadingData(false)
            } else {
                setResponse({
                    success: false,
                    message: updateResponse.data.error
                        ? updateResponse.data.error
                        : "Something went wrong. Server couldn't process your data",
                })
                setLoadingData(false)
                setPersonDetails({
                    ...personDetailsSource,
                })
            }
            // Keeping commented code this for refrence

            // setResponse({
            //     success: updateResponse.isSuccess,
            //     message: updateResponse.isSuccess
            //         ? 'Successfully Updated'
            //         : updateResponse.statusMessage,
            // })

            // if (updateResponse.isSuccess) {
            //     setShowWarningModal(false)
            //     setUnsavedChanges(false)
            //     const res = updateResponse.data
            //     setPersonDetails({
            //         ...res,
            //         manager:
            //             res.managerFirstName || res.managerLastName
            //                 ? `${res.managerFirstName} ${res.managerLastName}`
            //                 : null,
            //     })
            //     setPersonDetailsSource({
            //         ...res,
            //         manager:
            //             res.managerFirstName || res.managerLastName
            //                 ? `${res.managerFirstName} ${res.managerLastName}`
            //                 : null,
            //     })
            //     setLoadingData(false)
            // } else {
            //     setPersonDetails({
            //         ...personDetailsSource,
            //     })
            //     setLoadingData(false)
            // }
        } else if (updateError) {
            setResponse({
                success: false,
                message:
                    "Something went wrong. Server couldn't process your data",
            })
            setLoadingData(false)
            setPersonDetails({
                ...personDetailsSource,
            })
        }
    }, [updateResponse, updateError])

    const handleUpdate = () => {
        setModalData({
            ...modalData,
            data: {
                ...modalData.data,
            },
        })
        setLoadingData(true)
        setUpdateApi(true)
    }

    useEffect(() => {
        if (updateApi) {
            updatePerson(bindMappingFieldsToUpdate())
            setUpdateApi(false)
        }
    }, [personDetails, updateApi, updatePerson])

    // FC: to check modified fields to send to API
    const bindMappingFieldsToUpdate = () => {
        let modifiedData = {}
        const fields = config.find((c) => c.name === modalData.section).fields

        fields.map((item) => {
            if (modalData.data[item.name] !== personDetails[item.name]) {
                const keyName = item.name
                const keyValue = modalData.data[item.name]
                modifiedData = { ...modifiedData, [keyName]: keyValue }
            }
        })

        if (moment(modalData.data['oofStartDate']).isAfter(moment().utc())) {
            delete modifiedData?.isOutOfOffice
        }
        return modifiedData
    }

    const classes = useStyles()
    const [modalData, setModalData] = React.useState({
        open: false,
        data: undefined,
        section: undefined,
    })

    useEffect(() => {
        if (!modalData.section) {
            setUnsavedChanges(false)
        } else if (modalData.data === personDetails) {
            setUnsavedChanges(false)
        } else {
            setUnsavedChanges(false)
            const fields = config.find(
                (c) => c.name === modalData.section,
            ).fields
            fields.map((item) => {
                if (modalData.data[item.name] !== personDetails[item.name]) {
                    setUnsavedChanges(true)
                }
            })
        }
    }, [modalData.data, modalData.section])

    const setModalOpen = (section) => {
        setModalData({
            open: true,
            data: personDetails,
            section: section,
        })
        setResponse(undefined)
    }

    const handleOoFFieldChange = (status) => {
        if (status.key === 'isOutOfOffice' && !status.value) {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    [status.key]: status.value,
                    oofStartDate: null,
                    oofEndDate: null,
                    oofInternalMsg: '',
                    oofExternalMsg: '',
                    oofAudience: 'none',
                    oofStatus: 'Disabled',
                },
            })
        } else {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    [status.key]: status.value,
                },
            })
        }
    }

    const handleOoOUpdate = () => {
        setResponse(undefined)
        setModalData({
            ...modalData,
            data: {
                ...modalData.data,
                oofAudience:
                    modalData.data.isOutOfOffice && modalData.data.oofAudience
                        ? modalData.data.oofAudience
                        : 'none',
                oofInternalMsg:
                    modalData.data.oofInternalMsg &&
                    modalData.data.oofInternalMsg
                        ? modalData.data.oofInternalMsg
                        : `${t('OoOInternalMsg')} ${
                              modalData.data.mobilePhone
                                  ? modalData.data.mobilePhone
                                  : '+1988888xxxx'
                          }`,
                oofExternalMsg:
                    modalData.data.oofAudience &&
                    modalData.data.oofAudience === 'none'
                        ? ''
                        : modalData.data.oofExternalMsg,
                oofStartDate:
                    modalData.data.oofStatus &&
                    modalData.data.oofStatus !== 'Scheduled'
                        ? null
                        : modalData.data.oofStartDate,
                oofEndDate:
                    modalData.data.oofStatus &&
                    modalData.data.oofStatus !== 'Scheduled'
                        ? null
                        : modalData.data.oofEndDate,
            },
        })

        setLoadingData(true)
        setUpdateApi(true)
    }

    const EditModalBody = () => {
        const fields = config.find((c) => c.name === modalData.section).fields
        const { t } = useTranslation()
        const handleChange = (input) => {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    [input.target.name]: input.target.value,
                },
            })
        }

        const handleTimeZoneChange = (timezone) => {
            setModalData({
                ...modalData,
                data: {
                    ...modalData.data,
                    globalTimeZoneID: timezone.globalTimeZoneID,
                    globalTimeZone: timezone.displayName,
                },
            })
        }

        return (
            <Grid container spacing={3}>
                {fields.map((item) => {
                    switch (item.type) {
                        case 'InputField':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.name}>
                                        <CustomField
                                            error={item.required}
                                            single
                                            label={t(item.label)}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )
                        case 'SelectField':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.name}>
                                        <CustomField
                                            error={item.required}
                                            select
                                            label={t(item.label)}
                                            options={item.options}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )

                        case 'SelectTimeZoneField':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.name}>
                                        <CustomField
                                            selectTimeZone
                                            label={t(item.label)}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleTimeZoneChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )

                        case 'DatePicker':
                            return (
                                <Grid item key={item.name}>
                                    <Box key={item.id}>
                                        <CustomField
                                            date
                                            error={item.required}
                                            label={t(item.label)}
                                            value={modalData.data[item.name]}
                                            name={item.name}
                                            disabled={
                                                item.disabled ? true : false
                                            }
                                            onChange={handleChange}
                                            style={item.style}
                                        />
                                    </Box>
                                </Grid>
                            )

                        default:
                            return <></>
                    }
                })}
            </Grid>
        )
    }

    const confirmClose = () => {
        if (unsavedChanges) {
            setShowWarningModal(true)
        } else {
            if (modalData.section === 'outOfOffice') {
                setShowOutOfOfficeModal(false)
            } else {
                setModalData({ section: '', open: false })
            }
        }
    }

    return (
        <MyIdentityLayout
            subHeader={
                <SubHeader
                    showBackButtonOnly={
                        location.pathname !==
                        `${appConfig.APP_SUBPATH}/myProfile`
                    }
                />
            }
        >
            <Box
                marginTop="20px"
                width="100%"
                paddingX="10px"
                paddingTop="80px"
            >
                <Box display="flex" className={classes.mainContainer}>
                    {personDetails && (
                        <>
                            <div className={classes.leftColumn}>
                                <Item>
                                    {isSelf &&
                                        !hideManageIdentityWorkflowButton &&
                                        canSeeManageYourIdentityButton && (
                                            <Box
                                                data-protectedsubcomponent={
                                                    canSeeManageYourIdentityButton
                                                }
                                                className={
                                                    classes.editProfileButton
                                                }
                                                onClick={() => {
                                                    query.set(
                                                        'workflowName',
                                                        'ManageYourIdentityWizard',
                                                    )
                                                    history.push(
                                                        `${
                                                            appConfig.APP_SUBPATH
                                                        }/workflows?${query.toString()}`,
                                                    )
                                                }}
                                            >
                                                <Icon
                                                    name="EditProfile"
                                                    style={{ margin: '12px' }}
                                                />
                                                <div
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    {t(
                                                        'ManageYourIdentity',
                                                    )}
                                                </div>
                                            </Box>
                                        )}
                                    {!isSelf &&
                                        !hideManageIdentityWorkflowButton &&
                                        canSeeManageSomeoneElseIdentityButton && (
                                            <Box
                                                data-protectedsubcomponent={
                                                    canSeeManageSomeoneElseIdentityButton
                                                }
                                                className={
                                                    classes.editProfileButton
                                                }
                                                onClick={() => {
                                                    query.set(
                                                        'workflowName',
                                                        'EditPerson',
                                                    )
                                                    query.set(
                                                        'resourceID',
                                                        personDetails.resourceId,
                                                    )
                                                    history.push(
                                                        `${
                                                            appConfig.APP_SUBPATH
                                                        }/workflows?${query.toString()}`,
                                                    )
                                                }}
                                            >
                                                <Icon
                                                    name="EditProfile"
                                                    style={{ margin: '12px' }}
                                                />
                                                <div
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    {t(
                                                        'ManageIdentity',
                                                    )}
                                                </div>
                                            </Box>
                                        )}
                                    <BasicInfoCard
                                        personGuid={personGuid}
                                        basicInfo={personDetails}
                                        config={config.find(
                                            (c) => c.name === 'basicInfo',
                                        )}
                                    />
                                    {hasAccessToOutOfOfficeCard && (
                                        <Box
                                            data-protectedsubcomponent={
                                                hasAccessToOutOfOfficeCard
                                            }
                                        >
                                            <OutOfOfficeCard
                                                oofInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'outOfOffice',
                                                )}
                                                handleModalOpen={() => {
                                                    setModalData({
                                                        open: false,
                                                        data: personDetails,
                                                        section: 'outOfOffice',
                                                    })
                                                    setResponse(undefined)
                                                    setShowOutOfOfficeModal(
                                                        true,
                                                    )
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Item>
                            </div>
                            <div className={classes.rightColumn}>
                                <Box display="flex" flexWrap="wrap">
                                    <Box className={classes.rightColumns}>
                                        <Item>
                                            <GeneralCard
                                                generalInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'generalInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('generalInfo')
                                                }
                                            />
                                        </Item>
                                        <Item>
                                            <OrganizationCard
                                                organizationInfo={personDetails}
                                                config={config.find(
                                                    (c) => c.name === 'orgInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('orgInfo')
                                                }
                                            />
                                        </Item>
                                    </Box>
                                    <Box className={classes.rightColumns}>
                                        <Item>
                                            <ContactCard
                                                contactInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'contactInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('contactInfo')
                                                }
                                            />
                                        </Item>
                                        <Item>
                                            <LocationCard
                                                locationInfo={personDetails}
                                                config={config.find(
                                                    (c) =>
                                                        c.name ===
                                                        'locationInfo',
                                                )}
                                                handleModalOpen={() =>
                                                    setModalOpen('locationInfo')
                                                }
                                            />
                                        </Item>
                                    </Box>
                                </Box>
                            </div>
                        </>
                    )}
                    {modalData.open && (
                        <EditModal
                            open={modalData.open}
                            onClose={confirmClose}
                            header={sectionDisplayNames[modalData.section]}
                            body={EditModalBody}
                            loading={loadingData}
                            updateData={handleUpdate}
                            handleDiscard={() =>
                                setModalOpen(modalData.section)
                            }
                            unsavedChanges={unsavedChanges}
                            response={response}
                        />
                    )}
                    {showOutOfOfficeModal && (
                        <OutOfOfficeModal
                            open={showOutOfOfficeModal}
                            onClose={() => setShowOutOfOfficeModal(false)}
                            loading={loadingData}
                            updateData={handleOoOUpdate}
                            handleDiscard={() => confirmClose()}
                            oofInfo={modalData.data}
                            handleOoFFieldChange={(e) =>
                                handleOoFFieldChange(e)
                            }
                            unsavedChanges={unsavedChanges}
                            response={response}
                            setResponse={() => setResponse(undefined)}
                        />
                    )}

                    {showWarningModal && (
                        <WarningModal
                            open={showWarningModal}
                            title={t('Confirm')}
                            description={t(
                                'ModalClosingWarningText',
                            )}
                            proceed={() => {
                                setShowWarningModal(false)
                                if (modalData.section === 'outOfOffice') {
                                    setShowOutOfOfficeModal(false)
                                } else {
                                    setModalData({ section: '', open: false })
                                }
                            }}
                            cancel={() => setShowWarningModal(false)}
                            yesLabel={t('Yes')}
                            noLabel={t('No')}
                        />
                    )}
                </Box>
            </Box>
        </MyIdentityLayout>
    )
}

IdentityDetails.propTypes = {
    personGuid: PropTypes.string.isRequired,
}

export default IdentityDetails
