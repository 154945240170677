import { usePaginatedQuery } from 'react-query'
import { Pagination } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useState } from 'react'

const useApiWithPagination = (
    queryKey,
    queryFn,
    queryConfig,
    defaultTake = 16,
    paginationProps,
) => {
    const [{ page }, dispatch] = usePageContext()

    const [take, setTake] = useState(defaultTake)

    const handlePageChange = (_, value) => {
        dispatch({
            type: 'SET_PAGE',
            payload: value,
        })
    }

    const handlePageSelection = (value) => {
        dispatch({
            type: 'SET_PAGE',
            payload: value,
        })
    }

    const handleItemsPerPageChange = (value) => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        setTake(value)
    }

    const skip = (page - 1) * take

    const { latestData, error, refetch } = usePaginatedQuery(
        [...queryKey, skip, take],
        () => queryFn(skip, take),
        queryConfig,
    )

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    const pagination = numberOfPages > 1 && (
        <Pagination
            count={numberOfPages}
            size="large"
            page={page}
            onChange={handlePageChange}
            parentIndex={9}
            showPageSelection={true}
            onPageSelection={handlePageSelection}
            itemsPerPage={take}
            showItemsPerPageSelection={true}
            onItemsPerPageChange={handleItemsPerPageChange}
            totalCount={latestData?.totalCount}
            showCountStat={true}
            {...paginationProps}
        />
    )

    return {
        loading: isLoading,
        list: latestData ? latestData.data : undefined,
        totalCount: latestData ? latestData.totalCount : undefined,
        rest: latestData ? latestData : undefined,
        pagination,
        error,
        revalidate: refetch,
    }
}

export default useApiWithPagination
