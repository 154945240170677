import CreateNewDelegateButton from './CreateNewDelegateButton'

const CreateNewBusinessRequestDelegateButton = () => {
    return (
        <CreateNewDelegateButton
            label="CreateBusinessRequestDelegation"
            title="CreateNewDelegation"
        />
    )
}

export default CreateNewBusinessRequestDelegateButton
