import { Fragment, useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useConfiguration } from 'appConfiguration/configurations'
import { PageContextProvider } from 'pageContext'
import { PermanentDelegations } from 'components'
import { Loader } from 'packages/eid-ui'
import config from 'config'

const PermanentDelegationsRouter = () => {
    const history = useHistory()
    let { path } = useRouteMatch()
    const { dropDownOptions } = useConfiguration()

    const currentOption = dropDownOptions.find((o) => o.route === path)

    const RedirectToFirstTab = () => {
        useEffect(() => {
            if (currentOption?.tabbedOptions?.length > 0) {
                history.push(
                    `${currentOption.route}${currentOption.tabbedOptions[0].route}`,
                )
            } else {
                history.push(`${config.APP_SUBPATH}/forbidden`)
            }
        }, [])

        return <Loader />
    }

    return (
        <Fragment>
            <Switch>
                <Route exact path={`${path}/`} component={RedirectToFirstTab} />
                {currentOption?.tabbedOptions.map((a) => (
                    <Route
                        key={a.name}
                        exact
                        path={currentOption.route + a.route}
                        component={(props) => (
                            <PageContextProvider>
                                <PermanentDelegations {...a} {...props} />
                            </PageContextProvider>
                        )}
                    />
                ))}
            </Switch>
        </Fragment>
    )
}
export default PermanentDelegationsRouter
