import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withRouter } from 'react-router'
import { Card, CardContent, CardActions, Box, Avatar, Typography, styled, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import appConfig from 'config'
import { TextFormatter, Button } from 'components'
import { MIDPathToImage } from 'packages/core/helpers'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'packages/eid-ui'

const colors = {
    primary: '#307fc1',
    grey: '#b4b4b4',
    border: '#f2f2f3',
}

const MyCard = styled(Card)({
    margin: 'auto',
    padding: '0px 10px 5px 10px',
})

const useStyles = makeStyles((theme) => ({
    header: {
        padding: '10px 8px 10px 8px',
        borderBottom: '1px solid ' + colors.border,
    },
    hr: {
        width: '92%',
        opacity: '0.4',
        borderColor: '#f5f5f5',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    content: {
        padding: '10px 15px',
        borderBottom: '1px solid ' + colors.border,
        display: 'flex',
    },
    companydetails: {
        width: '50%',
        '& p': {
            fontSize: '14px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '95%',
        },
    },
    bottomcontent: {
        padding: '10px 15px',
        display: 'flex',
    },
    persnalinfo: {
        width: '100%',
        '& p': {
            fontSize: '14px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '95%',
        },
    },
    actions: {
        paddingBottom: '12px',
        marginTop: '10px',
        '& button': {
            '&:hover': {
                boxShadow: '0 5px 5px -2px rgba(48, 127, 193, 0.55)',
            },
        },
    },
    noBorder: {
        border: 'none',
    },
    avatar: {
        width: '54px',
        height: '54px',
        boxShadow: '0 5px 8px 0 rgb(211 211 211)',
        border: 'solid 1px #ebebed',
        overflow: 'hidden',
        '& img': {
            boxShadow: '0 5px 8px 0 rgb(211 211 211)',
        },
    },
    CardHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px',
        borderBottom: '1px solid ' + colors.border,
    },
    profileimg: {
        width: '66px',
        height: '66px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(3px)',
        top: '12px',
    },
    table: {
        '& th': {
            color: colors.grey,
            fontWeight: 'normal',
            padding: '0px 5px',
        },
        '& td': {
            padding: '0px 5px',
        },
        tableLayout: 'fixed',
        width: '100%',
    },
    name: {
        fontSize: '14px',
        textAlign: 'left',
        textTransform: 'capitalize',
    },
    blueBold: {
        fontWeight: 'bold',
        color: '#307fc1',
        lineHeight: '1',
    },
    blue: {
        color: '#307fc1',
    },
    fieldsPadding: {
        padding: '0 7px',
    },
    fieldBlock: {
        '& div': {
            textAlign: 'left',
        },
        '&:first-child': {
            paddingRight: '10px',
            '& div': {
                float: 'right',
            },
        },
        '&:last-child': {
            paddingLeft: '10px',
        },
    },
}))

function PeopleCard({ route, history, data }) {
    const {
        id,
        firstName,
        lastName,
        company,
        email,
        pathToImage,
        login,
    } = data
    const classes = useStyles()
    const { t } = useTranslation()

    const {
        location: { pathname },
    } = history

    return (
        <MyCard className={classes.card}>
            <Box className={classes.CardHeader}>
                <Box
                    className={classes.profileimg}
                     style={{ position: 'relative', marginRight: '40px' }}
                >
                    <Avatar
                        src={
                            pathToImage &&
                            MIDPathToImage(appConfig.BASE_EID_URL, pathToImage)
                        }
                        aria-label="recipe"
                        className={classes.avatar}
                    />
                </Box>
                <Box className={classes.fieldBlock}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                lineHeight: '1',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('FirstName')}
                        </Typography>
                        <Typography variant="body2">
                            <Link
                                className={clsx(classes.blue, classes.name)}
                                component="button"
                                onClick={() =>
                                    history.push(
                                        `${pathname}/${id}?cardView=true`,
                                    )
                                }
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                <TextFormatter
                                    value={firstName}
                                    maxTextWidth={110}
                                />
                            </Link>
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.fieldBlock}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                lineHeight: '1',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('LastName')}
                        </Typography>
                        <Typography
                            className={clsx(classes.blue, classes.name)}
                            variant="body2"
                        >
                            <TextFormatter
                                value={lastName}
                                maxTextWidth={110}
                            />
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <CardContent className={classes.content}>
                <Box className={classes.companydetails}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                lineHeight: '1',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('CompanyName')}
                        </Typography>
                        <Typography variant="body2">
                            {company ? company : '-'}
                        </Typography>
                    </Box>
                </Box>
                {/* <Box className={classes.companydetails}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                            }}
                            variant="subtitle1"
                        >
                            Company ID
                        </Typography>
                        <Typography variant="body2">
                            {companyId ? companyId : '-'}
                        </Typography>
                    </Box>
                </Box> */}
                <Box className={classes.companydetails}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                lineHeight: '1',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('Login')}
                        </Typography>
                        <Tooltip title={login}>
                            <Typography variant="body2">
                                {login ? login : '-'}
                            </Typography>
                        </Tooltip>
                    </Box>
                </Box>
            </CardContent>

            <CardContent className={classes.bottomcontent}>
                <Box className={classes.persnalinfo}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                lineHeight: '1',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('Email')}
                        </Typography>
                        <Tooltip title={email}>
                            <Typography variant="body2">
                                {email ? email : '-'}
                            </Typography>
                        </Tooltip>
                    </Box>
                </Box>
                {/* <Box className={classes.persnalinfo2}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                            }}
                            variant="subtitle1"
                        >
                            Main Professional Id
                        </Typography>
                        <Typography variant="body2">No</Typography>
                    </Box>
                </Box> */}
            </CardContent>

            <CardActions disableSpacing className={classes.actions}>
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        padding: '5px 5px',
                    }}
                >
                    <Button.ViewProfile
                        onClick={() =>
                            history.push(`${pathname}/${id}?cardView=true`)
                        }
                    >
                        {t('ViewProfile')}
                    </Button.ViewProfile>
                </div>
            </CardActions>
        </MyCard>
    )
}

PeopleCard.propTypes = {
    data: PropTypes.object.isRequired,
}

export default withRouter(PeopleCard)