import React, { useState } from 'react'
import { MyIdentityLayout } from 'components'
import * as DynamicComponents from 'components/DynamicComponents'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Paper } from '@mui/material'
import SubHeader from 'components/SubHeader'
import { Header, WorkflowIFrame } from 'packages/eid-ui'
import { useHistory } from 'react-router'
import { useQuery, useGetControlsAccess } from 'packages/core'
import { useWorkflows } from 'hooks/workflowHooks'
import { Skeleton } from '@mui/material';
import WorkFlowsItems from './WorkflowItems.tsx'
import './workFlow.css'
import config from 'config'
import { useApplicationSettings } from 'hooks'
import { usePageContext } from 'pageContext'
import { useIsSmallScreen } from 'packages/core'

const WorkFlows = (route, dataSource, attributes, filters, subHeaderButton) => {
    const { t } = useTranslation()
    const query = useQuery()

    const history = useHistory()

    const [, dispatch] = usePageContext()

    const { data: appConfig } = useApplicationSettings()
    const styleConfig = appConfig.style

    const { data, isLoading } = useWorkflows()
    const workflowName = query.get('workflowName')
    const resourceID = query.get('resourceID')
    const wfParams = []
    if (resourceID) {
        wfParams.push(`SelectedResourceID=${resourceID}`)
    }

    const [openFilters, setOpenFilters] = useState(false)

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToAdvancedSearchFilters =
        filters && controls.includes(filters[0].requireAccess?.control)

    const renderComponent = (name) => {
        const DynamicComponentToRender = DynamicComponents[name]

        return <DynamicComponentToRender />
    }

    const subHeaderButtonComponent =
        subHeaderButton &&
        (controls.includes(subHeaderButton.requireAccess.control) ? (
            renderComponent(subHeaderButton.name)
        ) : (
            <></>
        ))

    const handleSearch = (text) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'searchKey',
                value: text,
            },
        })
    }

    const isSmallScreen = useIsSmallScreen()

    return (
        <MyIdentityLayout
            subHeader={
                <SubHeader
                    showNavTabs={true}
                    subHeaderButton={subHeaderButtonComponent}
                    showFilters={openFilters}
                    toggleFilters={() => setOpenFilters((prev) => !prev)}
                    showFiltersButton={hasAccessToAdvancedSearchFilters}
                    showBackButton={workflowName}
                />
            }
        >
            {workflowName ? (
                <Box
                    className="workflowIframe"
                    style={{ top: isSmallScreen ? '-26px' : 80 }}
                >
                    <WorkflowIFrame
                        baseEidUrl={config.BASE_EID_URL}
                        workflowName={workflowName}
                        onComplete={() => {
                            query.delete('workflowName')
                            history.push(
                                history.location.pathname,
                                query.toString(),
                            )
                        }}
                        workflowParams={wfParams}
                        height={'80vh'}
                        loadingMessage={t('WorkflowLoadingMessage')}
                    />
                </Box>
            ) : (
                <Paper className={'root workflow'}>
                    {styleConfig.search?.show && (
                        <Grid
                            container
                            style={{ justifyContent: 'start', gap: '24px' }}
                        >
                            <Grid
                                className="workflowsearchgrid hideonlowresolution"
                                md={4}
                                xs={12}
                                spacing={2}
                            />
                            <Grid
                                className="workflowsearchgrid"
                                md={4}
                                xs={12}
                                spacing={2}
                            />
                            <Grid
                                className="workflowsearchgrid"
                                md={4}
                                xs={12}
                                spacing={2}
                            >
                                <Header.Search
                                    styleData={styleConfig.search?.style}
                                    placeholder={t('Search')}
                                    handleSearch={handleSearch}
                                    showClearIcon={false}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        style={{ justifyContent: 'start', gap: '24px' }}
                    >
                        <>
                            {isLoading && !data ? (
                                <Box width={'100%'}>
                                    <Skeleton height={100} />
                                </Box>
                            ) : data?.length > 0 ? (
                                data.map((item, index) => (
                                    <WorkFlowsItems
                                        key={`workflow-item${index}`}
                                        title={item.friendlyName}
                                        description={item.description}
                                        isEnabled={item.requestWorkflowName}
                                        onClick={() => {
                                            item.requestWorkflowName
                                                ? history.push(
                                                      `${history.location.pathname}?workflowName=${item.requestWorkflowName}`,
                                                  )
                                                : history.push(
                                                      `${history.location.pathname}`,
                                                  )
                                        }}
                                    />
                                ))
                            ) : (
                                <div>{t('NoWorkflowsFound')}</div>
                            )}
                        </>
                    </Grid>
                </Paper>
            )}
        </MyIdentityLayout>
    )
}
export default WorkFlows
