import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Avatar } from 'packages/eid-ui'
import { MIDPathToImage } from 'packages/core/helpers'
import appConfig from 'config'

const useStyles = makeStyles(() => ({
    root: {
        marginLeft: '0.4rem',
    },
}))

const CellAvatar = ({ attribute, data }) => {
    const avatarProps = {
        size: 'large',
        src:
            data[attribute.src] &&
            MIDPathToImage(appConfig.BASE_EID_URL, data[attribute.src]),
    }

    const classes = useStyles()

    return <Avatar {...avatarProps} className={classes.root} />
}

export default CellAvatar
