import { Box, styled } from '@mui/material'

const Line = styled(Box)({
    display: 'flex',

    height: '1px',
    width: '100%',

    backgroundColor: '#01ae8f',
})

const ArrowShape = styled(Box)({
    width: 0,
    height: 0,
    opacity: 0.25,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '10px solid #01ae8f',

    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
})

const ArrowIcon = (props) => {
    const { color, ...rest } = props
    return (
        <Box
            display="flex"
            alignItems="center"
            width="100%"
            maxWidth="160px"
            {...rest}
        >
            <Line
                style={{
                    backgroundColor: color,
                    width: '85px',
                    opacity: '0.25'
                }}
            />
            <ArrowShape style={{ borderLeftColor: color }} />
        </Box>
    )
}

export default ArrowIcon
