import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '40px !important',
        color: '#ffffff !important',
        backgroundColor: '#307fc1 !important',
        border: '1px solid #ffffff',
        margin: 0,
        boxShadow: 'none',
        minWidth: '65px !important',
        borderRadius: '4px !important',
        padding: '8px 12px',

        '&:hover': {
            color: '#307fc1 !important',
            backgroundColor: '#ffffff !important',
            borderColor: '#307fc1 !important',
        },
        ...props.styles,
    }),
    icon: {
        color: '#ffffff',
        fill: '#ffffff',
        marginLeft: 6,
    },
}))
const NewDelegationButton = ({ color, styles, children, ...rest }) => {
    const classes = useStyles({ color, styles })
    return (
        <Button
            size="small"
            className={classes.root}
            {...rest}
            startIcon={<Icon name="Plus" />}
        >
            {children}
        </Button>
    )
}

export default NewDelegationButton
