import { usePageContext } from 'pageContext'
import { useQuery } from 'react-query'
import { useAxios } from '../packages/core'
export const WORKFLOWS_KEY = 'WORKFLOWS'

export const useWorkflows = () => {
    const callApi = useAxios()

    const [{ searchKey }] = usePageContext()

    let url = `api/UIActions/byNoun?noun=MyIdentity&verb=SelfService`

    if (searchKey) {
        url = url + `&searchTerm=${searchKey}`
    }

    return useQuery([WORKFLOWS_KEY, searchKey], () =>
        callApi({
            method: 'GET',
            url: url,
        }).then((data) => data.data),
    )
}
