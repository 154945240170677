import React, { useEffect, Fragment } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { getWorkflowWaitTimeInSeconds, useQuery } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import withRequiredData from './withRequiredData'
import { useConfiguration } from 'appConfiguration/configurations'
import { IdentityDetails } from 'components'
import { NotificationsPage } from 'containers/NotificationsPage'
import { PageContextProvider } from 'pageContext'
import { OrgChart } from 'containers/OrgChart'
import WorkFlows from 'containers/Workflows'
import WhitePages from 'containers/WhitePages'
import { useAuthState } from 'packages/core/auth'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import appConfig from 'config'
import { useAppState } from 'appContext'
import PermanentDelegationsRouter from 'components/PermanentDelegations/PermanentDelegationsRouter'
import { IdleTimer } from 'packages/eid-ui'
import { useSessionTimeout } from 'hooks'

const RedirectToFirstArea = () => {
    const { dropDownOptions } = useConfiguration()

    const history = useHistory()
    useEffect(() => {
        if (dropDownOptions.length > 0) {
            history.push(`${dropDownOptions[0].route}`)
        } else {
            history.push(`${appConfig.APP_SUBPATH}/forbidden`)
        }
    }, [])

    return <Loader />
}

const App = () => {
    const [, appDispatch] = useAppState()
    const [{ currentUserId }] = useAuthState()
    const query = useQuery()
    const { data: loginSessionTimeout } = useSessionTimeout()
    const [{ isWorkflowIRenderable }, authDispatch] = useAuthState()
    const workflowWaitTime = getWorkflowWaitTimeInSeconds() * 1000

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, workflowWaitTime)
        }
    }, [])

    useEffect(() => {
        if (appConfig.ENABLE_DEV_FEATURES?.toLowerCase() === 'true') {
            appDispatch({
                type: 'ENABLE_DEV_FEATURES',
            })
        }
        if (query.get('enableDevFeatures')) {
            query.delete('enableDevFeatures')
            appDispatch({
                type: 'ENABLE_DEV_FEATURES',
            })
        }
    }, [])

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${appConfig.BASE_EID_URL}/UI/Common/SAML2/HiddenBlankPage`}
                    />
                </div>
            )}
            <Switch>
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/notifications`}
                    component={(props) => (
                        <PageContextProvider>
                            <NotificationsPage {...props} />
                        </PageContextProvider>
                    )}
                />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/orgChart/:personGuid`}
                    component={(props) => {
                        const { history, match } = props
                        const personGuid = match.params.personGuid

                        const validGuidRegex = new RegExp(
                            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                        )
                        if (!validGuidRegex.test(personGuid)) {
                            history.push(`${appConfig.APP_SUBPATH}/NotFound`)
                        }

                        return (
                            <PageContextProvider>
                                <OrgChart {...props} personGuid={personGuid} />
                            </PageContextProvider>
                        )
                    }}
                />
                <Route
                    key="PermanentDelegations"
                    path={`${appConfig.APP_SUBPATH}/permanentDelegations`}
                    component={(props) => (
                        <PageContextProvider>
                            <PermanentDelegationsRouter {...props} />
                        </PageContextProvider>
                    )}
                />
                <Route
                    key="Workflows"
                    exact
                    path={`${appConfig.APP_SUBPATH}/workflows`}
                    component={(props) => (
                        <PageContextProvider>
                            <WorkFlows {...props} />
                        </PageContextProvider>
                    )}
                />

                <Route
                    key="whitePages"
                    path={`${appConfig.APP_SUBPATH}/whitePages`}
                >
                    <PageContextProvider>
                        <WhitePages />
                    </PageContextProvider>
                </Route>

                <Route
                    key="myProfile"
                    path={`${appConfig.APP_SUBPATH}/myProfile`}
                >
                    <PageContextProvider>
                        <IdentityDetails personGuid={currentUserId} />
                    </PageContextProvider>
                </Route>

                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/`}
                    component={RedirectToFirstArea}
                />

                <Route exact path={`/`} component={RedirectToFirstArea} />

                <Redirect from="" to={`${appConfig.APP_SUBPATH}/notfound`} />
            </Switch>
            {loginSessionTimeout && (
                <IdleTimer loginSessionTimeout={loginSessionTimeout} />
            )}
        </Fragment>
    )
}

export default withRequiredData(App)
