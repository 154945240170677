import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS'

export const useApplicationSettings = (settingsOverride) => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: settingsOverride
                    ? `/api/application/settings?settingsOverride=${settingsOverride}`
                    : '/api/application/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAllApplications = () => {
    const callApi = useAxios()
    return useQuery(
        ALL_APPLICATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/access/applications',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useEidGlobalSettings = () => {
    const requiredSettings = [
        'MyID_Hide_Edit_Button_Via_Workflow',
        'MyID_Show_Edit_Buttons_Via_Profile_Sections',
    ]

    let queryParams = ''

    for (let i = 0; i < requiredSettings.length; i++) {
        if (i > 0) {
            queryParams = queryParams.concat('&')
        }
        queryParams = queryParams.concat(`name=${requiredSettings[i]}`)
    }

    const callApi = useAxios()
    return useQuery(
        [APPLICATION_SETTINGS_KEY, 'GLOBAL_SETTINGS'],
        () =>
            callApi({
                method: 'GET',
                url: `/api/application/EIDGlobalSetting?${queryParams}`,
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
