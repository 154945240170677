import { Row, useStyles } from './utils'
import { Box } from '@mui/material'
import { TimeRangeSelector } from './TimeRangeSelector'

export const NotificationControlWithDaySelector = (props: any) => {
    const {
        label,
        description,
        noOfDays,
        handleChange,
        handleUpdate,
        active,
        loading,
    } = props
    const classes = useStyles()

    return (
        <>
            <Box className={classes.digestNotificationContainer}>
                <Row
                    active={active}
                    label={label}
                    description={description}
                    onChange={handleUpdate}
                    loading={loading}
                />
            </Box>
            {active && (
                <Box padding="16px">
                    <TimeRangeSelector
                        noOfDays={noOfDays}
                        handleChange={handleChange}
                        loading={loading}
                    />
                </Box>
            )}
        </>
    )
}
