import {
    isNilOrEmpty,
    useGetPagesAccess,
    useGetControlsAccess,
} from 'packages/core'

import { IConfigurationOption } from 'models'
import { whitePagesSubOptions } from './whitePagesSubOptions'
import appConfig from 'config'
import { permanentDelegationsSubOptions } from './permanentDelegationsSubOptions'
import { useAppState } from 'appContext'

export const configuration: IConfigurationOption[] = [
    {
        name: 'WhitePages',
        title: 'WhitePages',
        route: `${appConfig.APP_SUBPATH}/whitePages`,
        icon: 'WhitePages',
        requireAccess: {
            page: 'MyIdentity-WhitePages-Page',
        },
        tabbedOptions: whitePagesSubOptions,
    },
    {
        name: 'PermanentDelegations',
        title: 'PermanentDelegations',
        route: `${appConfig.APP_SUBPATH}/permanentDelegations`,
        icon: 'TeamChat',
        requireAccess: {
            page: 'MyIdentity-PermanentDelegations-Page',
        },
        tabbedOptions: permanentDelegationsSubOptions,
    },
    {
        name: 'Workflowss',
        title: 'Workflows',
        route: `${appConfig.APP_SUBPATH}/workflows`,
        icon: 'Workflows',
        requireAccess: {
            page: 'MyIdentity-Workflows-Page',
        },
    },
    {
        name: 'EmailNotifications',
        title: 'EmailNotifications',
        route: `${appConfig.APP_SUBPATH}/notifications`,
        icon: 'EmailNotification',
        requireAccess: {
            page: 'MyIdentity-NotificationsSettings-Page',
        },
    },
    {
        name: 'ViewProfile',
        title: 'ViewProfile',
        route: `${appConfig.APP_SUBPATH}/myProfile`,
        icon: 'ViewProfile',
    },
]

export const useConfiguration = () => {
    const accessiblePages = useGetPagesAccess()
    const controls = useGetControlsAccess().map((c: any) => c.name)
    const [{ devFeaturesEnabled }]: any = useAppState()

    const noAccessRequired = (option: IConfigurationOption) =>
        isNilOrEmpty(option.requireAccess?.page)

    const hasRequiredAccess = (option: IConfigurationOption) =>
        accessiblePages.findIndex(
            (x: any) => x.name === option.requireAccess?.page,
        ) > -1

    const canAccess = (option: IConfigurationOption) =>
        noAccessRequired(option) || hasRequiredAccess(option)

    //Filtering pages based on access
    let filteredOptions = configuration.filter(
        (page) => noAccessRequired(page) || hasRequiredAccess(page),
    )

    const hasAccessToControl = (controlName: any) => {
        return controls.includes(controlName) ? controlName : ''
    }

    filteredOptions = filteredOptions.map((p) => {
        let page = { ...p }
        if (page.tabbedOptions) {
            // Filtering tabs based on access and devFeature
            page.tabbedOptions = page.tabbedOptions.filter(
                (tab) =>
                    canAccess(tab) && (!tab.isDevFeature || devFeaturesEnabled),
            )
        }
        return page
    })

    const hasAccessToNotificationSettings = accessiblePages
        .map((p: any) => p.name)
        .includes('MyIdentity-NotificationsSettings-Page')

    const hasAccessToDetailedNotificationSettings = hasAccessToControl(
        'MyIdentity-DetailedNotificationsSettings-Control',
    )

    const canSeeDigestEmailNotificationSettings = hasAccessToControl(
        'MyIdentity-DigestEmailNotificationsSettings-Control',
    )

    const canSeeFunctionsAndRisksNotificationSettings = hasAccessToControl(
        'MyIdentity-FunctionsAndRisksNotificationsSettings-Control',
    )

    return {
        dropDownOptions: filteredOptions,
        hasAccessToNotificationSettings,
        hasAccessToDetailedNotificationSettings,
        canSeeDigestEmailNotificationSettings,
        canSeeFunctionsAndRisksNotificationSettings,
    }
}
