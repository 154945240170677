import { useState } from 'react'
import { Box } from '@mui/material'
import { Loader } from 'packages/eid-ui'
import {
    useDigestEmailsNotificationPreference,
    useEditDigestEmailNotification,
} from 'hooks'
import { NotificationControlWithDaySelector } from './NotificationControlWithDaySelector'
import { useTranslation } from 'react-i18next'

export const DigestEmails = ({ type = 'Default' }) => {
    const [itemIdToUpdate, setItemIdToUpdate] = useState(null)

    const { t } = useTranslation()
    const {
        data: digestEmailNotification,
        isLoading,
    } = useDigestEmailsNotificationPreference(type)

    const [
        editDigestEmailNotification,
        { isLoading: updating },
    ] = useEditDigestEmailNotification()

    const handleUpdate = (id, active, notifyEveryXDays) => {
        const data = {
            id,
            active,
            notifyEveryXDays,
        }
        setItemIdToUpdate(id)
        editDigestEmailNotification(data)
    }
    const handleUpdateDaysChange = (id, active, notifyEveryXDays) => {
        if (notifyEveryXDays > 0) {
            const data = {
                id,
                active,
                notifyEveryXDays,
            }
            setItemIdToUpdate(id)
            editDigestEmailNotification(data)
        }
    }

    if (isLoading) {
        return (
            <Box width="100%" padding="20px">
                <Loader margin="auto" />
            </Box>
        )
    }

    return (
        <Box>
          
            {digestEmailNotification && digestEmailNotification.length === 0 &&
                <Box display='flex'
                    justifyContent='center'
                    width='100%'
                    padding='20px'
                >
                    {t('NoNotificationSettingsFound')}
                </Box>
            }

            {digestEmailNotification &&
                digestEmailNotification.map((n) => (
                    <NotificationControlWithDaySelector
                        active={n.active}
                        key={
                            n.localizedNotificationReportSubscriptionDescription
                        }
                        label={
                            n.localizedNotificationReportSubscriptionDescription
                        }
                        noOfDays={n.notifyEveryXDays}
                        handleChange={(notifyEveryXDays) =>
                            handleUpdateDaysChange(
                                n.id,
                                n.active,
                                notifyEveryXDays,
                            )
                        }
                        handleUpdate={(active) =>
                            handleUpdate(n.id, active, n.notifyEveryXDays)
                        }
                        loading={updating ? n.id === itemIdToUpdate : false}
                    />
                ))}
        </Box>
    )
}
