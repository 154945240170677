import React, { useRef, useState } from 'react'
import { Dropdown, Loader } from 'packages/eid-ui'
import { MenuItem, styled } from '@mui/material'
import { GlobeIcon, ArrowIcon } from 'packages/eid-icons'
import TimeZoneMenuItem from './TimeZoneMenuItem'
import { useTimeZones } from 'hooks'
import { useTranslation } from 'react-i18next'

const MenuHeader = styled(MenuItem)({
    color: '#ced1d4',
    fontSize: '11px',
    height: '40px',
    '& span': {
        textTransform: 'uppercase'
    },
    '&:hover': {
        backgroundColor: 'transparent !important',
    },

})

const TimeZoneList = ({ value, onChange, handleClose }) => {
    const { t } = useTranslation()
    const { isLoading, data: availableTimeZones } = useTimeZones()

    const [currentTimeZone, setCurrentTimeZone] = useState(value)
    const handleChange = (event, timezone) => {
        setCurrentTimeZone(timezone.displayName)
        onChange(timezone)
        handleClose(event)
    }

    return (
        <>
            {isLoading ? <Loader /> : (
                <>
                    <MenuHeader><span>{t('TimeZone')}</span></MenuHeader>
                    {availableTimeZones.map((timezone, index) => (
                        <TimeZoneMenuItem
                            key={`${timezone.name}_${index}`}
                            timezone={timezone}
                            onClick={(event) =>
                                handleChange(event, timezone)
                            }
                            selected={timezone.displayName === currentTimeZone}
                        ></TimeZoneMenuItem>
                    ))}
                </>
            )}

        </>
    )
}

const TimeZoneDropDown = React.forwardRef(
    ({ open, setOpen, value, onChange }, ref) => {

        const handleClose = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                return
            }

            setOpen(false)
        }
        return (
            <Dropdown
                open={open}
                rootStyles={{
                    paddingRight: '0px',
                    overflow: 'scroll'
                }}
                width="500px"
                listStyles={{
                    paddingBottom: '0px',
                    maxHeight: '250px',
                    '& > ul': {
                        paddingBottom: '7px',
                        paddingTop: '0px',
                    },
                }}
                anchorEl={ref.current}
                anchorOriginPosition={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorTransformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                handleClose={handleClose}
                showCone={false}
            >
                <TimeZoneList value={value} onChange={onChange} handleClose={handleClose} />
            </Dropdown>
        )
    }
)


const SelectTimeZone = ({ value, onChange }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const display = (

        <div style={{ display: 'flex', alignItems: 'center', padding: '0px 10px', cursor: 'pointer' }}
            onClick={() => { setOpen((prev) => !prev) }}>
            <GlobeIcon />
            <div style={{ padding: '0px 8px' }}>{value? value : t('SelectYourTimeZone')}</div>
            <ArrowIcon direction='down' color='#d8d8dd' />
            <span ref={anchorRef} style={{ position: 'absolute', top: '40px', left: '0px' }}></span>
        </div>
    )

    return (<>
        {display}
        {open && <><TimeZoneDropDown open={open} setOpen={setOpen} ref={anchorRef} value={value} onChange={onChange}>
        </TimeZoneDropDown></>}
    </>)
}




export default SelectTimeZone