import React from 'react'
import { styled, Box, Typography, InputBase } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Icon } from 'packages/eid-icons'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import moment from 'moment'

const Container = styled(Box)({
    height: '40px',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #ebebed',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    padding: '0px 15px',
    position: 'relative',
    cursor: 'pointer',
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        margin: '0px 16px',
        'label + &': {
            marginTop: theme.spacing(3),
        },
        cursor: 'pointer',
    },
    input: {
        position: 'relative',
        backgroundColor: '#ffffff !important',
        boxShadow: 'none',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: 16,
        color: '#5d6870',
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const DateTimeFilter = ({
    label,
    showLabelWithin,
    value,
    placeholder,
    onChange,
    disablePast,
    minDate,
    helperText,
    ...rest
}) => {
    const InputWithLabel = () => {
        return (
            <Box>
                <Typography
                    style={{
                        color: '#b4b4b4',
                        fontSize: '10px',
                        textTransform: 'uppercase',
                        minWidth: '60px',
                        padding: '10px 10px',
                    }}
                >
                    {label}
                </Typography>
                <NativeDatePicker
                    handleChange={(e) => {
                        onChange(e)
                    }}
                    value={value?.local().format('YYYY-MM-DD')}
                    minDate={
                        minDate
                            ? minDate.format('YYYY-MM-DD')
                            : disablePast
                            ? moment(new Date()).format('YYYY-MM-DD')
                            : ''
                    }
                >
                    <Container
                        padding="0px 5px !important"
                        boxShadow="none !important"
                    >
                        <Box
                            style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 5px',
                            }}
                        >
                            <Icon name="Calendar" color="#959598" />
                        </Box>
                        <StyledInput
                            value={
                                value
                                    ? moment(value)
                                          .clone()
                                          .local()
                                          .format('D MMMM YYYY')
                                    : ''
                            }
                            placeholder={helperText}
                        />
                    </Container>
                </NativeDatePicker>
            </Box>
        )
    }

    const InputComponent = () => {
        return (
            <NativeDatePicker
                handleChange={(e) => {
                    const date = moment(e)
                    onChange(date)
                }}
                value={value?.local().format('YYYY-MM-DD')}
                minDate={
                    minDate
                        ? minDate.format('YYYY-MM-DD')
                        : disablePast
                        ? moment(new Date()).format('YYYY-MM-DD')
                        : ''
                }
            >
                <Container>
                    <Box>
                        <Typography
                            style={{
                                color: '#b4b4b4',
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                minWidth: '60px',
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>

                    <StyledInput
                        value={
                            value
                                ? moment(value)
                                      .clone()
                                      .local()
                                      .format('D MMMM YYYY')
                                : ''
                        }
                    />

                    <Box
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '15px',
                        }}
                    >
                        <Icon name="Tasks" color="#D8D8DD" />
                    </Box>
                </Container>
            </NativeDatePicker>
        )
    }

    return <>{showLabelWithin ? <InputComponent /> : <InputWithLabel />}</>
}

export default DateTimeFilter
