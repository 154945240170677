import { useEffect, useState, useCallback } from 'react'
import {
    Grid,
    Box,
    Typography,
    Collapse,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import {
    MyIdentityLayout,
    SubHeader,
    TableContainer,
    FiltersColumn,
} from 'components'
import TableView from './TableView'
import DelegationCard from './DelegationCard'
import {
    useIsSmallScreen,
    isNilOrEmpty,
    useGetControlsAccess,
} from 'packages/core'
import {
    useApplicationSettings,
    useDelegationsListingData,
    useDeleteDelegation,
} from 'hooks'
import { usePageContext } from 'pageContext'
import { EidButton, Header, Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Card as FilterCard, ToggleSwitch } from 'packages/eid-ui'
import AdvancedFilter from 'components/EidFilter/AdvancedFilter'
import * as DynamicComponents from 'components/DynamicComponents'
import { config, SwitchContainer } from './utils'
import CustomButton from 'components/CustomButton'
import CardSortingMenu from 'components/CardSortingMenu/CardSortingMenu'

const subHeaderHeight = 105
const renderPeopleGrid = (
    rows,
    route,
    assignedToMe,
    fallbackMessage,
    handleCardChecked,
    selectAll,
    setSelectAll,
    selected,
    primaryKey,
    attributes,
) => {
    if (rows.length === 0) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                height="40vh"
                margin="auto"
                alignItems="center"
            >
                <Typography variant="body2">{fallbackMessage}</Typography>
            </Box>
        )
    }

    return (
        <Grid container spacing={2}>
            {rows.map((r, index) => (
                <Grid item xs={12} sm={6} md={4} key={'people-grid-' + index}>
                    <DelegationCard
                        rowSelection={true}
                        data={r}
                        route={route}
                        handleCardChecked={handleCardChecked}
                        selectAll={selectAll}
                        setSelectAll={setSelectAll}
                        checked={selected.indexOf(r[primaryKey]) >= 0}
                        hideActions={assignedToMe}
                        showDelegator={assignedToMe}
                        attributes={attributes}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const PermanentDelegations = ({ route, showCollaborationTasksDelegations }) => {
    const { t } = useTranslation()
    const [{ sortBy, sortOrder, assignedToMe }, dispatch] = usePageContext()

    const configToUse = useCallback(() => {
        if (showCollaborationTasksDelegations) {
            return assignedToMe
                ? config.collaborationTasksAssignments
                : config.collaborationTasksDelegations
        } else {
            return assignedToMe
                ? config.businessRequestAssignments
                : config.businessRequestDelegations
        }
    }, [showCollaborationTasksDelegations, assignedToMe])

    const { dataSource, attributes, subHeaderButton, filters } = configToUse()
    const primaryKey = showCollaborationTasksDelegations
        ? 'delegateePersonId'
        : 'id'

    const fallbackMessage = t('NoDelegationsFound')

    const isSmallScreen = useIsSmallScreen()

    const [showCardView, toggleShowCardView] = useState(false)
    const [openFilters, setOpenFilters] = useState(false)
    const [selectAll, setSelectAll] = useState(false) //only for cards
    const [selected, setSelected] = useState([])
    const selectedCount = selected.length
    const isSelected = selectedCount > 0

    const [deleteDelegations, { isLoading: isDeletingDelegations, isSuccess }] =
        useDeleteDelegation(dataSource.deleteUrl, dataSource.url)
    useEffect(() => {
        if (isSuccess) {
            setSelected([])
        }
    }, [isSuccess, isDeletingDelegations])

    const handleSelect = (selectedArray) => {
        setSelected(selectedArray)
    }

    const handleDelete = () => {
        deleteDelegations(selected)
    }
    const handleCardChecked = (item) => {
        const selectedIndex = selected.indexOf(item[primaryKey])
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item[primaryKey])
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }
        handleSelect(newSelected)
    }
    const searchIdentity = (text) => {
        dispatch({
            type: 'SEARCH_IDENTITIES',
            payload: text,
        })
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectAll(true)
            const newSelecteds = list.map((n) => n[primaryKey])
            handleSelect(newSelecteds)
            return
        }
        setSelectAll(false)
        handleSelect([])
    }

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToAdvancedSearchFilters =
        filters && controls.includes(filters[0].requireAccess?.control)

    const filteredAttributes = attributes.filter(
        (a) =>
            isNilOrEmpty(a.requireAccess?.control) ||
            controls.includes(a.requireAccess?.control),
    )

    const { list, loading, pagination } = useDelegationsListingData(
        dataSource.url,
    )

    const renderComponent = (name) => {
        const DynamicComponentToRender = DynamicComponents[name]

        return <DynamicComponentToRender />
    }
    const { data: appConfig } = useApplicationSettings()

    const styleConfig = appConfig.style
    const subHeaderButtonComponent =
        subHeaderButton &&
        (controls.includes(subHeaderButton.requireAccess.control) ? (
            renderComponent(subHeaderButton.name)
        ) : (
            <></>
        ))

    const desktopFilters = (
        <Box marginLeft="20px">
            <Collapse in={openFilters} timeout={300} unmountOnExit>
                <FiltersColumn openFilters={openFilters}>
                    <FilterCard
                        fontColor="black"
                        cardTitle={t('AdvancedSearch')}
                        icon={<Icon name="AdvancedSearch" color="#959598" />}
                    >
                        <AdvancedFilter
                            forms={filters && filters[0].forms}
                            tags={null}
                        />
                    </FilterCard>
                </FiltersColumn>
            </Collapse>
        </Box>
    )
    const actions = (
        <Box
            display="flex"
            padding="40px 10px 0px 10px"
            justifyContent="space-between"
        >
            <Box width="300px">
                {!assignedToMe && (
                    <EidButton.DrawerActionButton
                        disabled={!isSelected || loading}
                        fontColor="#ffffff"
                        bgColor="#E40000"
                        width="100%"
                        onClick={handleDelete}
                        loading={false}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            height={'12px'}
                            width={'12px'}
                        >
                            <Icon
                                name="DeleteX"
                                fill="#ffffff"
                                color="#ffffff"
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography
                                style={{
                                    color: '#ffffff',
                                }}
                            >
                                {t('RemoveSelected', {
                                    count: selectedCount,
                                })}
                            </Typography>
                        </Box>
                    </EidButton.DrawerActionButton>
                )}
            </Box>
            <Box style={{ marginBottom: '3.2rem' }}>{pagination}</Box>
        </Box>
    )
    const listing = (
        <Box position="relative">
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '-60px',
                    marginBottom: '12px',
                }}
            >
                <Box style={{ display: 'flex', gap: '32px' }}>
                    {!isSmallScreen && !assignedToMe && showCardView && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        list?.length > 0 &&
                                        selectedCount === list?.length
                                    }
                                    icon={<Icon name="CheckBox" />}
                                    checkedIcon={<Icon name="CheckedBox" />}
                                    onChange={(e) => handleSelectAllClick(e)}
                                />
                            }
                            label={t('SelectAll')}
                        />
                    )}
                    {/* Below empty div is intentional instead of empty element <></> */}
                    {assignedToMe || !subHeaderButtonComponent ? (
                        <div></div>
                    ) : (
                        subHeaderButtonComponent
                    )}
                </Box>

                <Box style={{ display: 'flex' }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginRight={'22px'}
                    >
                        <SwitchContainer assignedToMe={assignedToMe}>
                            <Typography className="Label">
                                {t('CreatedByMe')}
                            </Typography>
                            <ToggleSwitch
                                value={assignedToMe}
                                onChange={() =>
                                    dispatch({
                                        type: 'SET_PROP',
                                        payload: {
                                            key: 'assignedToMe',
                                            value: !assignedToMe,
                                        },
                                    })
                                }
                            />

                            <Typography className="SubLabel">
                                {t('AssignedToMe')}
                            </Typography>
                        </SwitchContainer>
                    </Box>
                    {styleConfig.search?.show && (
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                padding: '8px 0px 16px 0px',
                                width: isSmallScreen ? '100%' : '325px',
                                float: 'right',
                            }}
                        >
                            <Header.Search
                                styleData={styleConfig.search?.style}
                                placeholder={t('Search')}
                                handleSearch={searchIdentity}
                                showClearIcon={false}
                            />
                        </div>
                    )}
                    {!isSmallScreen && showCardView && (
                        <Box
                            style={{
                                display: 'flex',
                                padding: '8px 16px 0px 16px',
                            }}
                        >
                            <CardSortingMenu
                                attributes={filteredAttributes}
                                primaryProp={'columnName'}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            {loading && <Loader margin="auto" />}

            {list && (
                <>
                    {isSmallScreen && (
                        <>
                            {openFilters && (
                                <Collapse
                                    in={openFilters}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Box boxShadow="0 4px 6px -2px rgba(0, 0, 0, 0.1)">
                                        <FiltersColumn
                                            width="100%"
                                            openFilters={openFilters}
                                        >
                                            <FilterCard
                                                fontColor="black"
                                                cardTitle={t('AdvancedSearch')}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                            >
                                                <AdvancedFilter
                                                    forms={
                                                        filters &&
                                                        filters[0].forms
                                                    }
                                                    tags={null}
                                                />
                                                <Box
                                                    display="flex"
                                                    marginTop="24px"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <CustomButton
                                                        label={t('Apply')}
                                                        onClick={() =>
                                                            setOpenFilters(
                                                                false,
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            </FilterCard>
                                        </FiltersColumn>
                                    </Box>
                                </Collapse>
                            )}
                            {!openFilters && (
                                <Collapse
                                    in={!openFilters}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {renderPeopleGrid(
                                        list,
                                        route,
                                        assignedToMe,
                                        fallbackMessage,
                                        handleCardChecked,
                                        selectAll,
                                        setSelectAll,
                                        selected,
                                        primaryKey,
                                        filteredAttributes,
                                    )}
                                </Collapse>
                            )}
                        </>
                    )}

                    {!isSmallScreen && (
                        <>
                            {showCardView ? (
                                <TableContainer
                                    openFilters={openFilters}
                                    width="100% !important"
                                >
                                    <Box>
                                        <Box display={'flex'}>
                                            <Box>
                                                {renderPeopleGrid(
                                                    list,
                                                    route,
                                                    assignedToMe,
                                                    fallbackMessage,
                                                    handleCardChecked,
                                                    selectAll,
                                                    setSelectAll,
                                                    selected,
                                                    primaryKey,
                                                    filteredAttributes,
                                                )}
                                                {!assignedToMe && actions}
                                            </Box>
                                            <Box>
                                                {openFilters && desktopFilters}
                                            </Box>
                                        </Box>
                                    </Box>
                                </TableContainer>
                            ) : (
                                <>
                                    <TableContainer openFilters={openFilters}>
                                        <Box style={{ width: '100%' }}>
                                            <TableView
                                                fallbackMessage={
                                                    fallbackMessage
                                                }
                                                headings={filteredAttributes}
                                                route={route}
                                                sort={(sortBy, sortOrder) => {
                                                    dispatch({
                                                        type: 'SORT_IDENTITIES',
                                                        payload: {
                                                            sortBy,
                                                            sortOrder,
                                                        },
                                                    })
                                                }}
                                                sortBy={sortBy}
                                                data={list}
                                                sortOrder={sortOrder}
                                                onSelect={handleSelect}
                                                selected={selected}
                                                primaryKey={
                                                    showCollaborationTasksDelegations
                                                        ? 'delegateePersonId'
                                                        : 'id'
                                                }
                                                pagination={pagination}
                                                rowSelection={!assignedToMe}
                                            />
                                            {actions}
                                        </Box>
                                        <Box>
                                            {openFilters && desktopFilters}
                                        </Box>
                                    </TableContainer>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
    )

    const subHeader = (
        <>
            <Box width="100%">
                <SubHeader
                    showNavTabs={true}
                    showCardView={showCardView}
                    toggleCardView={(showCardView) =>
                        toggleShowCardView(showCardView)
                    }
                    showFilters={openFilters}
                    toggleFilters={() => setOpenFilters((prev) => !prev)}
                    showFiltersButton={hasAccessToAdvancedSearchFilters}
                />
            </Box>
        </>
    )

    return (
        <MyIdentityLayout subHeader={subHeader}>
            <Box paddingTop={`${subHeaderHeight + 45}px`} paddingX="10px">
                {listing}
            </Box>
        </MyIdentityLayout>
    )
}

export default PermanentDelegations
