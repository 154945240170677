import {
    useGetControlsAccess,
    isNilOrEmpty,
    useGetPagesAccess,
} from 'packages/core'

const useSubcomponents = () => {
    const controls = useGetControlsAccess().map((c) => c.name)

    const pages = useGetPagesAccess().map((p) => p.name)

    const hasAccessToControl = (controlName) => {
        return controls.includes(controlName) ? controlName : ''
    }

    const trimControlsBasedOnAccess = (controlsList) =>
        controlsList.filter((f) =>
            f.requireAccess ? hasAccessToControl(f.requireAccess) : true,
        )

    const canSeeAtLeastOneFilter = (resourceType) =>
        resourceType.filters.filter((f) =>
            f.requireAccess
                ? controls.indexOf(f.requireAccess.control) >= 0
                : true,
        ).length > 0

    const getAccessBasedAttributes = (attributes) => {
        const result = attributes.filter((a) => {
            if (isNilOrEmpty(a.requireAccess)) {
                return true
            }
            if (controls.findIndex((x) => x === a.requireAccess.control) > -1) {
                return true
            }
            return false
        })

        return result
    }

    const trimPagesBasedOnAccess = (pagesList) =>
        pagesList.filter((f) =>
            f.requireAccess ? pages.includes(f.requireAccess) : true,
        )

    return {
        canSeeAtLeastOneFilter,
        trimControlsBasedOnAccess,
        getAccessBasedAttributes,
        controls,
        pages,
        trimPagesBasedOnAccess,
        hasAccessToControl,
    }
}
export default useSubcomponents
