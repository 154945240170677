import { useConfiguration } from 'appConfiguration'
import { useEidGlobalSettings } from 'hooks'

export const useIdentityConfig = (isSelf) => {
    const { data: globalSettings } = useEidGlobalSettings()

    const showEditIdentityProfileSections =
        globalSettings?.find(
            (x) => x.name === 'MyID_Show_Edit_Buttons_Via_Profile_Sections',
        )?.value === 'true'

    const {
        canSeeManageYourIdentityButton,
        canSeeManageSomeoneElseIdentityButton,
    } = useConfiguration()

    const canSeeEditOption =
        showEditIdentityProfileSections &&
        (isSelf
            ? canSeeManageYourIdentityButton
            : canSeeManageSomeoneElseIdentityButton)

    const config = [
        {
            name: 'basicInfo',
            type: 'profile',
            editable: true,
            showEditOption: true,
            fields: [
                {
                    name: 'firstName',
                    type: 'InputField',
                    label: 'FirstName',
                    required: false,
                    style: {
                        fontWeight: 'bold',
                        color: '',
                        fontSize: '24px',
                        textDecoration: '',
                        width: 180,
                    },
                },
                {
                    name: 'lastName',
                    type: 'InputField',
                    label: 'LastName',
                    required: false,
                    style: {
                        fontWeight: '',
                        color: '',
                        textDecoration: '',
                        fontSize: '24px',
                        width: 180,
                    },
                },
                {
                    name: 'jobTitle',
                    type: 'InputField',
                    label: 'JobTitle',
                    required: false,
                    style: { width: 180 },
                },
                {
                    name: 'department',
                    type: 'SelectField',
                    label: 'Department',
                    required: false,
                    disabled: true,
                    options: ['Marketing', 'HR', 'Finance', 'IT'],
                    style: { width: 180 },
                },
            ],
        },

        {
            name: 'contactInfo',
            type: 'general',
            editable: true,
            showEditOption: canSeeEditOption,
            fields: [
                {
                    name: 'email',
                    type: 'InputField',
                    label: 'Email',
                    required: false,
                    cardFieldType: 'singleField',
                    borderBelow: true,
                    link: true,
                    style: {
                        textDecoration: '',
                        fontSize: '',
                        width: 180,
                    },
                },
                {
                    name: 'contactEmail',
                    type: 'InputField',
                    label: 'EmailContact',
                    required: false,
                    cardFieldType: 'singleField',
                    borderBelow: true,
                    style: {
                        textDecoration: 'underline',
                        fontSize: '',
                        width: 180,
                    },
                },
                {
                    name: 'telephone',
                    type: 'InputField',
                    label: 'Telephone',
                    required: false,
                    cardFieldType: 'inLineField',
                    divider: true,
                    style: { width: 180 },
                },
                {
                    name: 'mobilePhone',
                    type: 'InputField',
                    label: 'MobilePhone',
                    required: false,
                    cardFieldType: 'inLineField',
                    divider: false,
                    style: { width: 180 },
                },
            ],
        },
        {
            name: 'generalInfo',
            type: 'general',
            editable: true,
            showEditOption: canSeeEditOption,
            fields: [
                {
                    name: 'firstName',
                    type: 'InputField',
                    label: 'FirstName',
                    required: false,
                    style: { fontWeight: 'bold', fontSize: '', width: 180 },
                },
                {
                    name: 'lastName',
                    type: 'InputField',
                    label: 'LastName',
                    required: false,
                    style: { fontWeight: 'bold', fontSize: '', width: 180 },
                },
                {
                    name: 'corporateId',
                    type: 'InputField',
                    label: 'CorporateId',
                    required: false,
                    disabled: true,
                    style: { width: 180 },
                },
                {
                    name: 'globalTimeZone',
                    type: 'SelectTimeZoneField',
                    label: 'YourTimeZone',
                    required: 'false',
                },
                {
                    name: 'globalTimeZoneID',
                },
            ],
        },
        {
            name: 'locationInfo',
            type: 'general',
            editable: true,
            showEditOption: canSeeEditOption,
            fields: [
                {
                    name: 'country',
                    type: 'InputField',
                    label: 'Country',
                    required: false,
                    disabled: true,
                    style: { width: 260 },
                },
                {
                    name: 'city',
                    type: 'InputField',
                    label: 'City',
                    required: false,
                    disabled: true,
                    style: { width: 210 },
                },
                {
                    name: 'siteName',
                    type: 'InputField',
                    label: 'Site',
                    disabled: true,
                    required: false,
                    style: { width: 180 },
                },
                {
                    name: 'building',
                    type: 'InputField',
                    label: 'Building',
                    required: false,
                    disabled: true,
                    style: { width: 180 },
                },
                {
                    name: 'office',
                    type: 'InputField',
                    label: 'Office',
                    required: false,
                    style: { width: 180 },
                },
                {
                    name: 'streetAddress',
                    type: 'InputField',
                    label: 'Street',
                    required: false,
                    style: { width: 290 },
                },
                {
                    name: 'postalCode',
                    type: 'InputField',
                    label: 'PostalCode',
                    required: false,
                    style: { width: 130 },
                },
            ],
        },
        {
            name: 'orgInfo',
            type: 'general',
            editable: true,
            showEditOption: canSeeEditOption,
            fields: [
                {
                    name: 'company',
                    type: 'InputField',
                    label: 'Company',
                    required: false,
                    disabled: true,
                    style: { fontWeight: 'bold', width: 230 },
                },
                {
                    name: 'jobTitle',
                    type: 'InputField',
                    label: 'JobTitle',
                    required: false,
                    style: { width: 180 },
                },
                {
                    name: 'department',
                    type: 'InputField',
                    label: 'Department',
                    required: false,
                    disabled: true,
                    cardFieldType: 'inLineField',
                    style: { width: 185 },
                },
                {
                    name: 'login',
                    type: 'InputField',
                    label: 'Login',
                    required: false,
                    cardFieldType: 'inLineField',
                    style: { color: '#307fc1', width: 161 },
                },
                {
                    name: 'endOfContract',
                    type: 'DatePicker',
                    label: 'EndOfContract',
                    required: false,
                    cardFieldType: 'inLineField',
                    disabled: true,
                    style: { width: 152 },
                },
                {
                    name: 'costCenter',
                    type: 'InputField',
                    label: 'CostCenter',
                    required: false,
                    disabled: true,
                    cardFieldType: 'inLineField',
                    style: { width: 152 },
                },
                {
                    name: 'poBox',
                    type: 'InputField',
                    label: 'InternalPoBox',
                    required: false,
                    disabled: true,
                    cardFieldType: 'inLineField',
                    style: { width: 120 },
                },
                {
                    name: 'hrNumber',
                    type: 'InputField',
                    label: 'HrNumber',
                    required: false,
                    disabled: true,
                    cardFieldType: 'inLineField',
                    style: { width: 100 },
                },
                {
                    name: 'preferredLanguage',
                    type: 'InputField',
                    label: 'PreferredLanguage',
                    required: false,
                    disabled: true,
                    cardFieldType: 'inLineField',
                    style: { width: 185 },
                },
                {
                    name: 'manager',
                    type: 'InputField',
                    label: 'Manager',
                    required: false,
                    cardFieldType: 'inLineField',
                    disabled: true,
                    style: { width: 185 },
                },
            ],
        },
        {
            name: 'outOfOffice',
            type: 'general',
            editable: true,
            showEditOption: true,
            fields: [
                {
                    name: 'oofStartDate',
                },
                {
                    name: 'oofEndDate',
                },
                {
                    name: 'oofInternalMsg',
                },
                {
                    name: 'oofAudience',
                },
                {
                    name: 'oofStatus',
                },
                {
                    name: 'isOutOfOffice',
                },
                {
                    name: 'oofExternalMsg',
                },
            ],
        },
    ]

    return config
}
