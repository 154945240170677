import React, { useReducer, useEffect } from 'react'
import { Box, FormControlLabel, Checkbox } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import PeopleSearch from '../PeopleSearch'
import DateTimeFilter from '../DateTimeFilter'
import Button from '../Button'
import { isNilOrEmpty, useGetControlsAccess } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import moment from 'moment'
import { useCreateCollaborationTaskDelegation } from 'hooks'
import { styles, useStyles, ItemContainer, reducers } from './utils'

const NewCollaborationTaskDelegationForm = ({ onSave, onCancel }) => {
    const [state, dispatch] = useReducer(reducers, {
        people: [],
        startDate: moment().startOf('day').utc(),
        endDate: null,
        delegateForever: false,
        activeOnlyIfOutOfOffice: false,
    })

    const { t } = useTranslation()

    const classes = useStyles()

    const [createDelegation, { isLoading, isSuccess }] =
        useCreateCollaborationTaskDelegation()

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasOutOfOfficeAccess = controls.includes(
        'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control',
    )

    const handleCreateDelegation = () => {
        const dataToSubmit = {
            startDate: state.startDate,
            endDate: state.endDate,
            delegateePersonIds: state.people.map((p) => p.id),
        }
        if (hasOutOfOfficeAccess) {
            dataToSubmit.activeOnlyIfOutofOffice = state.activeOnlyIfOutOfOffice
        }
        createDelegation(dataToSubmit)
    }

    useEffect(() => {
        if (isSuccess) onSave()
    }, [isSuccess, onSave])

    const endDateCheck =
        (!state.delegateForever && isNilOrEmpty(state.endDate)) ||
        state.startDate?.isAfter(state.endDate)

    return (
        <>
            <ItemContainer>
                <PeopleSearch
                    url={'/api/v1/Delegation/approvers'}
                    endAdornmentComponent={() => <></>}
                    label={t('PersonName')}
                    placeholder={t('SelectPerson')}
                    onChange={(_, value) => {
                        dispatch({
                            type: 'SET_PEOPLE',
                            payload: value,
                        })
                    }}
                    rootStyleProps={styles.peopleSearch}
                    value={state.people}
                    multiple
                />
            </ItemContainer>

            <ItemContainer display="flex" marginLeft="12px">
                <FormControlLabel
                    classes={{
                        root: classes.formLabelMargin,
                        label: classes.label,
                    }}
                    control={
                        <Checkbox
                            checked={state.delegateForever}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            classes={{
                                checked: classes.checked,
                            }}
                            onChange={(e) =>
                                dispatch({
                                    type: 'SET_DELEGATE_FOREVER',
                                    payload: e.target.checked,
                                })
                            }
                        />
                    }
                    label={t('DelegateForever')}
                />
                {hasOutOfOfficeAccess && (
                    <FormControlLabel
                        data-protectedsubcomponent={
                            'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control'
                        }
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox
                                classes={{ checked: classes.checked }}
                                checked={state.activeOnlyIfOutOfOffice}
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'SET_OUT_OF_OFFICE',
                                        payload: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={t('OnlyWhenOutOfOffice')}
                    />
                )}
            </ItemContainer>

            <ItemContainer display="flex" marginLeft="-8px">
                <Box padding="0px 8px" className={classes.dateFilterContainer}>
                    <DateTimeFilter
                        disablePast={true}
                        margin="normal"
                        label={t('StartDate')}
                        placeholder={t('SelectDate')}
                        onChange={(date) => {
                            let dateToSet
                            if (!isNilOrEmpty(date)) {
                                dateToSet = moment(date)
                                    .clone()
                                    .startOf('day')
                                    .utc()
                            }
                            dispatch({
                                type: 'SET_START_DATE',
                                payload: dateToSet,
                            })
                        }}
                        value={
                            isNilOrEmpty(state.startDate)
                                ? null
                                : state.startDate.clone().local()
                        }
                    />
                </Box>

                {!state.delegateForever && (
                    <Box
                        padding="0px 8px "
                        className={classes.dateFilterContainer}
                    >
                        <DateTimeFilter
                            minDate={
                                !isNilOrEmpty(state.startDate) &&
                                state.startDate.clone().local()
                            }
                            disabled={state.delegateForever}
                            label={t('EndDate')}
                            placeholder={t('SelectDate')}
                            onChange={(date) => {
                                let dateToSet
                                if (!isNilOrEmpty(date)) {
                                    dateToSet = moment(date)
                                        .clone()
                                        .endOf('day')
                                        .subtract(1, 'minutes')
                                        .utc()
                                }
                                dispatch({
                                    type: 'SET_END_DATE',
                                    payload: dateToSet,
                                })
                            }}
                            value={
                                isNilOrEmpty(state.endDate)
                                    ? null
                                    : state.endDate.clone().local()
                            }
                        />
                    </Box>
                )}
            </ItemContainer>

            <ItemContainer
                display="flex"
                marginLeft="-8px"
                alignItems="center"
                flexDirection="row !important"
                flexWrap="wrap"
            >
                <Tooltip
                    title={
                        isNilOrEmpty(state.startDate) ||
                        isNilOrEmpty(state.people) ||
                        endDateCheck
                            ? t('RequiredFieldsMissing')
                            : ''
                    }
                >
                    <Box>
                        <Button.Rounded
                            loading={isLoading}
                            styles={{ minWidth: '144px !important' }}
                            disabled={
                                isNilOrEmpty(state.startDate) ||
                                isNilOrEmpty(state.people) ||
                                endDateCheck ||
                                isLoading
                            }
                            onClick={() => {
                                handleCreateDelegation()
                            }}
                        >
                            {t('CreateDelegation')}
                        </Button.Rounded>
                    </Box>
                </Tooltip>

                <Button.Rounded onClick={onCancel} styles={styles.cancelButton}>
                    {t('Cancel')}
                </Button.Rounded>
            </ItemContainer>
        </>
    )
}

export default NewCollaborationTaskDelegationForm
