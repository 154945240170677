import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const CardField = ({
    label,
    borderBelow,
    oofInfo,
}) => {
    const { t } = useTranslation()
    return (
        <Box>
            <div
                style={{
                    padding: '10px 0',
                    borderBottom: borderBelow ? '1px solid #efeff1' : '',
                }}
            >
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                    {label.toUpperCase()}
                </Typography>
                {oofInfo.isOutOfOffice ? (
                    <Box style={{ display: 'flex', marginTop: '30px' }}>
                        <Box
                            style={{
                                marginRight: '20px',
                                width: '32px',
                                height: '32px',
                                borderRadius: '50%',
                                background: '#9049a5',
                                textAlign: 'center',
                                padding: '6px',
                            }}
                        >
                            <Icon name={'OutOfOffice'} />
                        </Box>
                        <Typography
                            style={{
                                color: '#282828',
                                fontSize: '16px',
                                display: 'flex',
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>{t('OoO')} </span>
                            {oofInfo.oofEndDate && <span style={{ marginLeft: '3px' }}>
                                {`${t('Until')} ${moment.utc(oofInfo.oofEndDate).local().format('LLL')}`}
                            </span>}
                            
                        </Typography>
                    </Box>
                ) : (
                    <Box style={{ display: 'flex', marginTop: '30px' }}>
                        <Typography
                            style={{
                                color: '#282828',
                                fontSize: '16px',
                                display: 'flex',
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>{t('InOffice')}</span>
                        </Typography>
                    </Box>
                )}

            </div>
        </Box>
    )
}

CardField.propTypes = {
    variant: PropTypes.string,
}

CardField.defaultProps = {
    variant: 'body1',
}

export default CardField
