import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Icon } from 'packages/eid-icons'
import './workFlowItem.css'
import { useApplicationSettings } from 'hooks'

const useStyles = makeStyles(() => ({
    workflowHeader: (themeColors) => ({
        background: themeColors?.gradient,
        color: '#ffffff',
        '&:hover': {
            background: themeColors?.secondaryGradient,
            cursor: 'pointer',
        },
    }),
}))

const WorkFlowsItems = ({ title, description, onClick, isEnabled }) => {
    const { data: appConfig } = useApplicationSettings()
    const themeColors = appConfig.style?.colors
    const classes = useStyles(themeColors)

    return (
        <Grid
            onClick={onClick}
            item
            md={4}
            xs={12}
            spacing={2}
            className={`workflowinnergrid ${
                isEnabled ? 'show-workflow' : 'disable-workflow'
            }`}
        >
            <div
                className={
                    themeColors?.gradient && themeColors?.secondaryGradient
                        ? classes.workflowHeader
                        : 'gridtitle'
                }
            >
                <h2>{title}</h2>
            </div>
            <div className="workflowdescription inActive">
                <Icon name="WorkFlowChart" /> <p>{description}</p>
            </div>
            <div className="workflowdescription active">
                <Icon name="WorkFlowChartSelected" /> <p>{description}</p>
            </div>
        </Grid>
    )
}

export default WorkFlowsItems
