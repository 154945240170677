import { useState, useEffect, useCallback } from 'react'
import _axios from 'axios'

const axios = _axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
})

const useApi = (fn, callback) => {
    const [response, setResponse] = useState({
        data: null,
        loading: false,
        error: null,
    })
    const [request, setRequest] = useState()

    useEffect(() => {
        const cancellationSource = _axios.CancelToken.source()

        let _didCancel = false
        const callApi = async (request) => {
            try {
                const { data } = await axios(request)
                if (_didCancel) return
                setResponse({
                    data,
                    loading: false,
                    error: null,
                })
                if (callback) callback('success')
            } catch (error) {
                if (_didCancel) return
                setResponse({
                    data: null,
                    loading: false,
                    error: error,
                })
                if (callback) callback('error')
            }
        }

        if (!request || _didCancel) return
        setResponse({
            data: null,
            loading: true,
            error: null,
        })

        callApi({
            ...request,
            cancelToken: cancellationSource.token,
        })
        return () => {
            _didCancel = true
            cancellationSource.cancel('HTTP request cancelled.')
        }
    }, [request, callback])

    const memoizedCallback = useCallback((...args) => setRequest(fn(...args)), [
        fn,
    ])

    return [response, memoizedCallback]
}

export default useApi
