import React, { useRef } from 'react'
import { Box, styled, Typography, MenuItem, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'
import Loader from './Loader'
import { smallScreenWidth } from 'utils'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    minWidth: '160px',
    color: '#000000',
    borderLeft: '1.5px solid transparent',
    '&.Mui-selected': {
        borderLeft: `1.5px solid ${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.primary.light} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
        '& svg': {
            color: '#000000',
        },
    },

    '& svg': {
        marginRight: '20px',
    },
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'inherit !important',
        borderLeft: `1.5px solid ${theme.palette.primary.main}`,
        '& svg': {
            color: theme.palette.primary.main,
        },
    },
}))

const menuStyles = {
    paddingRight: '0px',
    marginLeft: '-8px',
    marginTop: '-18px',
    transformOrigin: '25% top !important',

    '& .MuiList-padding': {
        paddingTop: '18px',
        paddingBottom: '18px',
    },
}
const listStyles = {
    paddingTop: '0px',
    paddingBottom: '0px',
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: `fit-content`,
    position: 'relative',
    paddingBottom: '4px',
    height: '45px',
    color: '#5d6870',
    transition: 'all 0.15s ease-in-out',

    '& .Gradient-border': {
        backgroundImage: theme.palette.tertiary.gradient,
    },
    '&:hover': {
        cursor: 'pointer',
        color: theme?.palette?.primary?.main,
        '& .Gradient-border': {
            backgroundColor: theme.palette.primary.main,
            backgroundImage: 'none',
        },

        '& .Down-Arrow': {
            color: theme.palette.primary.main,
        },
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        // minWidth: '190px !important',
    },
}))

const Border = styled(Box)(({ theme, styles, open }) => ({
    position: 'absolute',
    bottom: '-4.2px',
    left: 0,
    borderWidth: '2px',
    height: '4px',
    width: '100%',
    backgroundColor: !open && theme.palette.primary.main,
    backgroundImage: open && theme.palette.tertiary.gradient,
    marginTop: '10px',
    ...styles,
}))

const useStyles = makeStyles((theme) => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        top: '45px',
        ...anchorPosition,
    }),
    icon: {
        transform: 'rotate(90deg)',
    },

    subLabel: {
        fontSize: '27px',
        fontWeight: 'bold',
        letterSpacing: '0.022em',
        [`@media (max-width:${smallScreenWidth})`]: {
            fontSize: '20px',
        },
    },
    totalCountLabel: {
        borderRadius: '8px',
        minWidth: '28px',
        height: '20px',
        paddingTop: '1px',
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px',
        marginTop: '-2px',
        fontWeight: 'bold',

        '& + svg': {
            marginRight: '-4px',
        },
    },
}))

const DropdownFilter = ({
    label,
    style,
    anchorPosition,
    showCone,
    loading,
    open,
    setOpen,
    children,
}) => {
    const classes = useStyles({ anchorPosition })
    const theme = useTheme()
    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    return (
        <>
            <Container
                style={{ ...style }}
                onClick={() => setOpen((prev) => !prev)}
            >
                <span ref={anchorRef} className={classes.dropdownAnchor} />

                <Typography display="block" className={classes.subLabel}>
                    {label}
                </Typography>

                <Box
                    marginLeft="14px"
                    height="24px"
                    display="flex"
                    alignItems="center"
                >
                    <Icon
                        className="Down-Arrow"
                        name="Arrow"
                        color={theme.palette.primary.main}
                    />
                </Box>

                <Border className="Gradient-border" open={open} />
            </Container>
            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                showCone={showCone}
                menuHeight="calc(50vh - 4rem)"
                width="313px"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                rootStyles={menuStyles}
                listStyles={listStyles}
            >
                {loading && <Loader />}
                {!loading && children}
            </Dropdown>
        </>
    )
}

DropdownFilter.Item = StyledMenuItem
export default DropdownFilter
