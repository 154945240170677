import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Row } from './utils'

export const NotificationsSwitch = (props: any) => {
    const { t } = useTranslation()

    const { active, handleUpdate, loading, classes } = props

    return (
        <Box className={classes.container}>
            <Row
                active={active}
                label={t('ReceiveEmailNotifications')}
                description={t('EnableDisableNotifications')}
                onChange={handleUpdate}
                loading={loading}
            />
        </Box>
    )
}
