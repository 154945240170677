import React, { useReducer, useEffect } from 'react'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import DateTimeFilter from '../DateTimeFilter'
import Button from '../Button'
import { isNilOrEmpty, useGetControlsAccess } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import moment from 'moment'
import { useEditDelegation } from 'hooks'
import { styles, useStyles, ItemContainer, reducers } from './utils'

const EditDelegationForm = ({ onSave, onCancel, delegatee, attributeData }) => {
    const [state, dispatch] = useReducer(reducers, {
        startDate: isNilOrEmpty(delegatee.startDateUtc)
            ? null
            : moment.utc(delegatee.startDateUtc),
        endDate: isNilOrEmpty(delegatee.endDateUtc)
            ? null
            : moment.utc(delegatee.endDateUtc),
        delegateForever:
            !delegatee.activeOnlyIfOutOfOffice &&
            isNilOrEmpty(delegatee.endDateUtc)
                ? true
                : false,
        activeOnlyIfOutOfOffice: delegatee.activeOnlyIfOutOfOffice,
    })

    const { editUrl, revalidateUrl, appendData } = attributeData.component

    const { t } = useTranslation()

    const classes = useStyles()

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasOutOfOfficeAccess = controls.includes(
        'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control',
    )

    const [editDelegation, { isLoading, isSuccess }] = useEditDelegation(
        editUrl,
        revalidateUrl,
        appendData,
    )
    const handleEdit = () => {
        const dataToSubmit = {
            id: delegatee.id,
            delegatorPersonID: delegatee.delegatorPersonID,
            delegateePersonId: delegatee.delegateePersonId,
            delegateeFriendlyName: delegatee.delegateeFriendlyName,
            approvalFlowStepId: delegatee.approvalFlowStepId,
            approvalFlowStepFriendlyName:
                delegatee.approvalFlowStepFriendlyName,

            startDateUtc: state.startDate,
            endDateUtc: state.endDate,
        }
        if (hasOutOfOfficeAccess) {
            dataToSubmit.activeOnlyIfOutOfOffice = state.activeOnlyIfOutOfOffice
        }

        editDelegation(dataToSubmit)
    }

    useEffect(() => {
        if (isSuccess) onSave()
    }, [isSuccess, onSave])

    const endDateCheck =
        (!state.delegateForever && isNilOrEmpty(state.endDate)) ||
        state.startDate?.isAfter(state.endDate)

    return (
        <>
            <ItemContainer display="flex" marginLeft="12px">
                <FormControlLabel
                    classes={{
                        root: classes.formLabelMargin,
                        label: classes.label,
                    }}
                    control={
                        <Checkbox
                            checked={state.delegateForever}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            classes={{
                                checked: classes.checked,
                            }}
                            onChange={(e) =>
                                dispatch({
                                    type: 'SET_DELEGATE_FOREVER',
                                    payload: e.target.checked,
                                })
                            }
                        />
                    }
                    label={t('DelegateForever')}
                />
                {hasOutOfOfficeAccess && (
                    <FormControlLabel
                        data-protectedsubcomponent={
                            'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control'
                        }
                        classes={{ label: classes.label }}
                        control={
                            <Checkbox
                                classes={{ checked: classes.checked }}
                                checked={state.activeOnlyIfOutOfOffice}
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'SET_OUT_OF_OFFICE',
                                        payload: e.target.checked,
                                    })
                                }
                            />
                        }
                        label={t('OnlyWhenOutOfOffice')}
                    />
                )}
            </ItemContainer>

            <ItemContainer display="flex" marginLeft="-8px">
                <Box padding="0px 8px" className={classes.dateFilterContainer}>
                    <DateTimeFilter
                        disablePast={true}
                        label={t('StartDate')}
                        placeholder={t('SelectDate')}
                        onChange={(date) => {
                            let dateToSet
                            if (!isNilOrEmpty(date)) {
                                dateToSet = moment(date)
                                    .clone()
                                    .startOf('day')
                                    .utc()
                            }
                            dispatch({
                                type: 'SET_START_DATE',
                                payload: dateToSet,
                            })
                        }}
                        value={
                            isNilOrEmpty(state.startDate)
                                ? null
                                : state.startDate.clone().local()
                        }
                    />
                </Box>

                {!state.delegateForever && (
                    <Box
                        padding="0px 8px "
                        className={classes.dateFilterContainer}
                    >
                        <DateTimeFilter
                            minDate={
                                !isNilOrEmpty(state.startDate) &&
                                state.startDate.clone().local()
                            }
                            label={t('EndDate')}
                            placeholder={t('SelectDate')}
                            onChange={(date) => {
                                let dateToSet
                                if (!isNilOrEmpty(date)) {
                                    dateToSet = moment(date)
                                        .clone()
                                        .endOf('day')
                                        .subtract(1, 'minutes')
                                        .utc()
                                }
                                dispatch({
                                    type: 'SET_END_DATE',
                                    payload: dateToSet,
                                })
                            }}
                            value={
                                isNilOrEmpty(state.endDate)
                                    ? null
                                    : state.endDate.clone().local()
                            }
                        />
                    </Box>
                )}
            </ItemContainer>

            <ItemContainer
                display="flex"
                marginLeft="-8px"
                flexDirection="row !important"
                flexWrap="wrap"
            >
                <Tooltip
                    title={
                        endDateCheck
                            ? t('RequiredFieldsMissing')
                            : ''
                    }
                >
                    <Box>
                        <Button.Rounded
                            styles={{ minWidth: '144px !important' }}
                            loading={isLoading}
                            disabled={
                                isNilOrEmpty(state.startDate) ||
                                endDateCheck ||
                                isLoading
                            }
                            onClick={() => {
                                handleEdit()
                            }}
                        >
                            {t('SaveChanges')}
                        </Button.Rounded>
                    </Box>
                </Tooltip>

                <Button.Rounded onClick={onCancel} styles={styles.cancelButton}>
                    {t('Cancel')}
                </Button.Rounded>
            </ItemContainer>
        </>
    )
}

export default EditDelegationForm
