import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import { styled, Typography, Box, useTheme } from '@mui/material'
import { useSwrApiGet } from 'packages/core'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'
import { useAppState } from 'appContext'
import appConfig from 'config'

const StyledBox = styled(Box)({
    '@media (max-width:1159px)': {
        display: 'none',
    },
})

const StyledChip = styled('div')({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '48px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:1159px)': {
        left: '2px',
    },
})

const DesktopHeader = ({ config }) => {
    const theme = useTheme()

    const [{ devFeaturesEnabled }] = useAppState()
    const { data: environmentData } = useSwrApiGet(
        () =>
            config.showEnvironment ? '/api/Application/EnvironmentName' : null,
        {
            dedupingInterval: 3600000,
        },
    )

    return (
        <Header
            bgColor={config.bgColor}
            borderBottom={
                config?.bottomColor
                    ? { borderBottom: `2px solid ${config.bottomColor}` }
                    : {}
            }
        >
            <Header.IconLink
                logo
                href={
                    config.eidLogo?.url
                        ? config.eidLogo?.url
                        : `${appConfig.APP_SUBPATH}/`
                }
                rightDivider={config.showEidLogo}
            >
                {config.showEidLogo && (
                    <>
                        {config.eidLogo?.type ? (
                            <>
                                {config.eidLogo?.type === 'icon' ? (
                                    <Icon
                                        style={config.eidLogo?.style}
                                        name={config.eidLogo?.value}
                                        color={theme?.palette?.primary?.main}
                                    />
                                ) : (
                                    <img
                                        style={config.eidLogo?.style}
                                        src={config.eidLogo?.value}
                                        alt={config.eidLogo?.name}
                                    />
                                )}
                            </>
                        ) : (
                            <EidLogoIcon />
                        )}
                    </>
                )}
            </Header.IconLink>
            <Header.IconLink logo href="/">
                {config.appLogo.type === 'icon' ? (
                    <Icon
                        style={config.appLogo.style}
                        name={config.appLogo.value}
                        color={theme.palette.primary.main}
                    />
                ) : (
                    <img
                        style={config.appLogo.style}
                        src={config.appLogo.value}
                        alt={config.appLogo.name}
                    />
                )}
            </Header.IconLink>
            <StyledBox
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {config.appTitle.type === 'text' ? (
                    <Header.Title
                        style={{
                            ...config.appTitle.style,
                            color: theme.palette.primary.main,
                        }}
                    >
                        {config.appTitle.value}
                    </Header.Title>
                ) : (
                    <Header.IconLink logo href="/">
                        <img
                            style={config.appTitle.style}
                            src={config.appTitle.value}
                            alt={config.appTitle.name}
                        />
                    </Header.IconLink>
                )}
            </StyledBox>
            {config.showEnvironment && environmentData?.environmentName && (
                <Box position="relative" width="32px">
                    <Tooltip title={environmentData?.environmentName}>
                        <StyledChip>
                            <Typography
                                noWrap
                                style={{
                                    fontSize: '8px',
                                    padding: '0px 1.5px',
                                }}
                            >
                                {environmentData.environmentName}
                            </Typography>
                        </StyledChip>
                    </Tooltip>
                </Box>
            )}

            {devFeaturesEnabled && (
                <Box position="relative">
                    <StyledChip>
                        <Typography
                            noWrap
                            style={{
                                fontSize: '8px',
                                padding: '0px 1.5px',
                            }}
                        >
                            - WIP
                        </Typography>
                    </StyledChip>
                </Box>
            )}
            <Header.RightSection>
                <Header.Item style={{ minWidth: '32px', padding: '0px 6px' }}>
                    <LanguageMenu
                        anchorPosition={{
                            right: '-32px',
                            top: '54px',
                        }}
                    />
                </Header.Item>
                <Header.Item
                    style={{ minWidth: '32px', padding: '0px 48px 0px 16px' }}
                >
                    <AccountDropdown />
                </Header.Item>
            </Header.RightSection>
        </Header>
    )
}
export default DesktopHeader
