import { FC } from 'react'
import { Box, Avatar, Link } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { getFullImageUrl } from 'utils'
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import appConfig from 'config'
import { getURLTarget } from 'packages/core'

const useStyles = makeStyles(
    createStyles({
        root: {
            width: '248px',
            height: '248px',
            margin: '26px 0 0',
            padding: '63px 1px 0',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            position: 'relative',
            '&:hover': {
                cursor: 'context-menu',
            },
        },
        content: {
            padding: '24px',
        },
        firstName: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#282828',
            marginRight: '4px',
        },
        lastName: {
            fontSize: '18px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#282828',
        },
        link: {
            '&:hover': {
                textDecoration: 'none',
                '& span': {
                    color: '#307fc1',
                },
            },
        },
        position: {
            fontSize: '13px',
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#9b9fa7',
        },
        bottomBorder: (props: any) => ({
            width: '245px',
            height: '2px',
            border: 'solid 0.7px',
            borderColor: props.color,
            backgroundColor: props.color,
            position: 'absolute',
            bottom: 0,
        }),
        avatarContainer: {
            backgroundColor: '#eef0f4',

            width: '82px',
            height: '82px',
            marginTop: '-104px',
            marginLeft: '83px',

            borderRadius: '50%',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        avatar: {
            width: '62px',
            height: '62px',
            borderRadius: '50%',
        },
        iconsContainer: {
            width: '100%',
            height: '30px',
            borderTop: ' solid 0.8px #e8e8ec',
            borderBottom: ' solid 0.8px #e8e8ec',
            margin: '8px 0px',

            display: 'flex',
            alignItems: 'center',

            '& svg': {
                width: '16px',
                height: '16px',
                marginRight: '8px',
            },

            '& div': {
                display: 'flex',
                alignItems: 'center',
            },
            '& a': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        department: (props: any) => ({
            height: '20px',
            borderRadius: '2px',
            fontWeight: 600,
            fontSize: '9px',
            textTransform: 'uppercase',
            display: 'table',
            alignItems: 'center',
            padding: '5px 10px',
            margin: '8px 0px',
            backgroundColor: props.baseColor,
            color: props.color,
        }),
    }),
)

export const Person: FC<{ person: any; colorValue: any }> = (props) => {
    const color = `rgb(${props.colorValue})`
    const baseColor = `rgba(${props.colorValue},0.38)`

    const classes = useStyles({ color, baseColor })

    const { person } = props

    const showLinks =
        person.email ||
        person.phone ||
        person.facebookProfileUr ||
        person.linkedInProfileUrl ||
        person.instagramProfileUrl ||
        person.twitterProfileUrl

    return (
        <Box className={classes.root}>
            <Box className={classes.avatarContainer}>
                <Avatar
                    src={getFullImageUrl(person.imageThumbUrl)}
                    className={classes.avatar}
                />
            </Box>

            <Box className={classes.content}>
                <Link
                    href={`${appConfig.APP_SUBPATH}/whitepages/people/${person.id}`}
                    target={getURLTarget()}
                    className={classes.link}
                >
                    <Box
                        className={classes.firstName}
                        component={'span'}
                        style={{ wordBreak: 'break-all' }}
                    >
                        {person.firstName}
                    </Box>
                    <Box component={'span'} className={classes.lastName}>
                        {person.lastName}
                    </Box>
                </Link>

                {(person.jobTitle || person.country) && (
                    <Box className={classes.position}>
                        {person.jobTitle && `${person.jobTitle} `}
                        {person.country}
                    </Box>
                )}

                {showLinks && (
                    <Box className={classes.iconsContainer}>
                        {person.email && (
                            <a href={`mailto:${person.email}`}>
                                <Tooltip
                                    title={person.email}
                                    disableInteractive={false}
                                >
                                    <div>
                                        <Icon name="EmailRound" />
                                    </div>
                                </Tooltip>
                            </a>
                        )}
                        {person.phone && (
                            <Tooltip
                                title={person.phone}
                                disableInteractive={false}
                            >
                                <div>
                                    <Icon name="PhoneRound" />
                                </div>
                            </Tooltip>
                        )}
                        {person.facebookProfileUrl && (
                            <Link
                                target={getURLTarget()}
                                href={`${person.facebookProfileUrl}`}
                            >
                                <Icon name="Facebook" />
                            </Link>
                        )}
                        {person.instagramProfileUrl && (
                            <Link
                                target={getURLTarget()}
                                href={`${person.instagramProfileUrl}`}
                            >
                                <Icon name="Instagram" />
                            </Link>
                        )}

                        {person.linkedInProfileUrl && (
                            <Link
                                target={getURLTarget()}
                                href={`${person.linkedInProfileUrl}`}
                            >
                                <Icon name="LinkedinRound" />
                            </Link>
                        )}
                        {person.twitterProfileUrl && (
                            <Link
                                target={getURLTarget()}
                                href={`${person.twitterProfileUrl}`}
                            >
                                <Icon name="TwitterRound" />
                            </Link>
                        )}
                    </Box>
                )}
                {person.department && (
                    <Box className={classes.department}>
                        {person.department}
                    </Box>
                )}
            </Box>

            <Box className={classes.bottomBorder} />
        </Box>
    )
}
