import React from 'react'
import { Icon, Box } from '@mui/material'
import { Divider } from 'packages/eid-ui'
import { ProfileScopeIcon, GlobeIcon } from 'packages/eid-icons'
import GenericCard from './Cards/GenericCard'
import GenericCardHeader from './Cards/GenericCardHeader'
import GenericCardContent from './Cards/GenericCardContent'
import CardFieldGroup from './Cards/CardFieldGroup'
import CardField from './Cards/CardField'
import { useTranslation } from 'react-i18next'


const GeneralCard = ({ generalInfo, handleModalOpen, config }) => {
    const { t } = useTranslation()
    const TimeZoneValue =  generalInfo.globalTimeZone && <>{generalInfo.globalTimeZone}<span style={{marginLeft:'8px'}}><GlobeIcon /></span></>

    return (
        <GenericCard
            onClick={config.editable ? handleModalOpen : undefined}
            editable={config.editable}
            showEditOption={config.showEditOption}
        >
            <GenericCardHeader
                backgroundColor="#fbfbfd"
                color="#b4b4b4"
                icon={
                    <Icon style={{ margin: '0.75em' }}>
                        <ProfileScopeIcon />
                    </Icon>
                }
                heading={t('General')}
                height={60}
            />

            <GenericCardContent>
                <CardFieldGroup>
                    <CardField
                        label={t('FirstName')}
                        value={generalInfo.firstName}
                        bold
                    />
                    <Divider vertical width={23} color="#ffffff" />
                    <CardField
                        label={t('LastName')}
                        value={generalInfo.lastName}
                        bold
                    />
                </CardFieldGroup>
                <CardFieldGroup>
                    <Box style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                    <CardField
                        label={t('CorporateId')}
                        value={generalInfo.corporateId}
                    />
                    <CardField
                        label={t('YourTimeZone')}
                        value={TimeZoneValue}
                        fieldStyles={{width:'45%', display: 'flex', justifyContent: 'flex-end', textAlign: 'end'}}
                    />
                    </Box>
                </CardFieldGroup>
            </GenericCardContent>
        </GenericCard>
    )
}

export default GeneralCard
