import { IConfigurationOption } from 'models'

export const permanentDelegationsSubOptions: IConfigurationOption[] = [
    {
        name: 'businessRequestDelegations',
        title: 'BusinessRequestDelegations',
        route: '/permanentBRDelegations',
        requireAccess: {
            page: 'MyIdentity-BusinessRequestDelegations-Page',
        },
    },
    {
        name: 'collaborationTasksDelegations',
        title: 'CollaborationTasksDelegations',
        route: '/permanentCTDelegations',
        requireAccess: {
            page: 'MyIdentity-CollaborationTasksDelegations-Page',
        },
        showCollaborationTasksDelegations: true,
    },
]
