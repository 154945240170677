import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import clsx from 'clsx'
import * as DynamicComponents from 'components/DynamicComponents'
import { isNilOrEmpty } from 'packages/core'
import { TextFormatter, useSortLabelClasses, styles, renderIcon } from './utils'

const PeopleTable = ({
    route,
    classes,
    headings,
    data,
    sort,
    sortBy,
    sortOrder,
    fallbackMessage,
    rowSelection,
    onSelect,
    selected,
    primaryKey,
}) => {
    const { t } = useTranslation()

    const sortLabelClasses = useSortLabelClasses()

    const getHeadingName = (heading) =>
        heading.columnName ? heading.columnName : heading.name
    const handleSort = (heading) => {
        sort(
            getHeadingName(heading),
            sortBy !== getHeadingName(heading)
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }
    useEffect(() => {
        onSelect([])
    }, [data])
    const renderComponent = (a, item) => {
        const DynamicComponentToRender = DynamicComponents[a.component.name]

        return <DynamicComponentToRender attribute={a} data={item} />
    }

    const renderTableContentCell = (a, row) => {
        const cellProps = {
            key: a.name,
            className: classes.tableCell,
            align: a.align,
            style: a.styles,
        }

        return (
            <React.Fragment key={a.name}>
                {!a.hideValue &&
                    (isNilOrEmpty(row[a.name]) ? (
                        <TableCell {...cellProps} style={null}>
                            -
                        </TableCell>
                    ) : (
                        <TableCell {...cellProps}>
                            {a.link ? (
                                <DynamicComponents.CellLink
                                    attribute={a}
                                    data={row}
                                    value={
                                        <TextFormatter
                                            item={row}
                                            attribute={a}
                                        />
                                    }
                                />
                            ) : (
                                <TextFormatter item={row} attribute={a} />
                            )}
                        </TableCell>
                    ))}

                {a.avatar && (
                    <TableCell {...cellProps}>
                        <DynamicComponents.CellAvatar
                            data={row}
                            attribute={a}
                        />
                    </TableCell>
                )}
                {a.component && (
                    <TableCell {...cellProps}>
                        {renderComponent(a, row, route)}
                    </TableCell>
                )}
            </React.Fragment>
        )
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) =>
                primaryKey ? n[primaryKey] : n.id,
            )
            onSelect(newSelecteds)
            return
        }
        onSelect([])
    }

    const handleClick = (item) => {
        const selectedIndex = selected.indexOf(
            primaryKey ? item[primaryKey] : item.id,
        )
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(
                selected,
                primaryKey ? item[primaryKey] : item.id,
            )
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        onSelect(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    return (
        <Table style={{ height: 'fit-content' }} className={classes.table}>
            <TableHead>
                <TableRow className={classes.tableRow}>
                    {rowSelection && (
                        <TableCell
                            padding="checkbox"
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                            style={{ paddingLeft: '4px' }}
                        >
                            <Checkbox
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                checked={
                                    data?.length > 0 &&
                                    selected.length === data?.length
                                }
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                    )}

                    {headings.map((heading) => (
                        <TableCell
                            key={heading.name}
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                            align={heading.align}
                        >
                            <>
                                {heading.sort ? (
                                    <Tooltip
                                        title={
                                            sortBy ===
                                                getHeadingName(heading) &&
                                            sortOrder === 'desc'
                                                ? t('SortAscending')
                                                : t('SortDescending')
                                        }
                                    >
                                        <TableSortLabel
                                            classes={sortLabelClasses}
                                            active={
                                                sortBy ===
                                                getHeadingName(heading)
                                            }
                                            direction={
                                                sortBy ===
                                                getHeadingName(heading)
                                                    ? sortOrder
                                                    : 'asc'
                                            }
                                            onClick={() => handleSort(heading)}
                                        >
                                            {t(heading.label)}
                                        </TableSortLabel>
                                    </Tooltip>
                                ) : (
                                    <span
                                        className={classes.tableHeaderContent}
                                    >
                                        {t(heading.label)}
                                        {renderIcon(
                                            heading,
                                            classes,
                                            sort,
                                            sortBy,
                                            sortOrder,
                                        )}
                                    </span>
                                )}
                            </>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>

            <TableBody>
                {data.length === 0 && (
                    <TableRow className={classes.tableRow}>
                        <TableCell
                            className={classes.tableCell}
                            colSpan={9}
                            style={{ textAlign: 'center' }}
                        >
                            {fallbackMessage}
                        </TableCell>
                    </TableRow>
                )}

                {data.map((row) => (
                    <TableRow key={row.id} className={classes.tableRow}>
                        {rowSelection && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    icon={<Icon name="CheckBox" />}
                                    checkedIcon={<Icon name="CheckedBox" />}
                                    checked={isSelected(
                                        primaryKey ? row[primaryKey] : row.id,
                                    )}
                                    onClick={() => handleClick(row)}
                                />
                            </TableCell>
                        )}
                        {headings.map((a) => renderTableContentCell(a, row))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

PeopleTable.propTypes = {
    headings: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
}

export default withStyles(styles)(PeopleTable)
