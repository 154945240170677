import { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm'
import { Typography, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Modal from '../Modal'

const IdleTimer = ({ loginSessionTimeout }) => {
    const [open, setOpen] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [count, setCount] = useState(0)
    const [state, setState] = useState('Active')
    const { t } = useTranslation()
    const history = useHistory()

    const No_Button = {
        border: '1px #2F7FC1 solid',
        borderRadius: '5px',
    }

    const Yes_Button = {
        bgcolor: '#2F7FC1',
        border: '1px #2F7FC1 solid',
        color: 'white',
        borderRadius: '5px',
        marginLeft: '15px',
    }

    const handleClose = () => {
        setClicked(true)
        setOpen(false)
    }

    const onActive = () => {
        setState('Active')
    }

    const onIdle = () => {
        setState('Non-active')
        setOpen(true)
    }

    const footerContent = (
        <Box>
            <Button
                sx={No_Button}
                onClick={() => {
                    history.push('/signout')
                    setClicked(true)
                    setOpen(false)
                }}
            >
                {t('No')}
            </Button>

            <Button variant="contained" sx={Yes_Button} onClick={handleClose}>
                {t('Yes')}
            </Button>
        </Box>
    )

    const headerContent = <Typography>{t('Login_Session_Timeout')}</Typography>

    const onAction = () => {
        setCount(count + 1)
    }

    useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: loginSessionTimeout,
        throttle: 500,
    })

    useEffect(() => {
        let timeoutId
        if (state !== 'Active' && !clicked && open) {
            timeoutId = setTimeout(() => {
                history.push('/signout')
            }, 300000)
        }

        return () => {
            clearTimeout(timeoutId)
        }
    }, [clicked, open, state])

    return (
        <div>
            <Modal
                headerContent={headerContent}
                open={open}
                onClose={handleClose}
                title={t('Login_Session_Timeout')}
                footerContent={footerContent}
                customDialogClass={{
                    '.MuiPaper-elevation': {
                        '& > #dialog-header': {
                            padding: '18px 20px !important',
                            alignItems: 'center',
                        },
                        '& > #dialog-body': {
                            padding: '20px 20px !important',
                        },
                        '& > #dialog-footer': {
                            padding: '15px 20px !important',
                        },
                    },
                }}
            >
                <Typography>{t('LoginSessionTimeoutMessage')}</Typography>
            </Modal>
        </div>
    )
}

export default IdleTimer
