import React, { useEffect, useState } from 'react'
import { useAuthState, useQuery } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import {
    useAccessSubcomponents,
    useTranslations,
    useCurrentPerson,
    useEidGlobalSettings,
} from 'hooks'
import moment from 'moment'
import i18n from 'i18next'

const useLoadAccessSubcomponents = () => {
    const [{ access }, dispatchAuthState] = useAuthState()
    const { data } = useAccessSubcomponents()

    useEffect(() => {
        if (data) {
            dispatchAuthState({
                type: 'SET_ACCESS_SUBCOMPONENTS',
                payload: data.data,
            })
        }
    }, [data, dispatchAuthState])

    return access !== null
}

const useLoadTranslations = () => {
    const RESOURCE_SET_NAME = 'MyIdentity'

    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useTranslations()
    const { data: currentPerson } = useCurrentPerson()
    const [refresh, setRefresh] = useState(false)

    const locale =
        currentPerson && currentPerson.language?.languageCode
            ? currentPerson.language?.languageCode
            : 'en'
    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                let keyToUse = r.key
                const parts = r.key.split('_')
                if (parts[0] === RESOURCE_SET_NAME) {
                    keyToUse = r.key.substring(RESOURCE_SET_NAME.length + 1)
                }

                if (debugLocalization) {
                    current[keyToUse] = r.key
                } else {
                    current[keyToUse] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'translation', localeData)
        }
    }, [data, locale, debugLocalization])

    const isResourceBundleReadyToUse =
        i18n.getResourceBundle(locale) !== undefined

    if (Boolean(data) && !isResourceBundleReadyToUse) {
        setTimeout(() => {
            setRefresh(!refresh)
        }, [1000])
    }
    return Boolean(data) && isResourceBundleReadyToUse
}

const useLoadGlobalEidSettings = () => {
    const { data } = useEidGlobalSettings()

    return Boolean(data)
}

const withRequiredData = (ChildComponent) => (props) => {
    const translationsLoaded = useLoadTranslations()
    const accessSubcomponentsLoaded = useLoadAccessSubcomponents()
    const globalConfigLoaded = useLoadGlobalEidSettings()

    if (
        !accessSubcomponentsLoaded ||
        !translationsLoaded ||
        !globalConfigLoaded
    ) {
        return <Loader />
    } else {
        return <ChildComponent {...props} />
    }
}

export default withRequiredData
