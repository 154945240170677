import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Button } from 'components'
import { useStyles } from './utils'


const resetButtonStyles = {
    backgroundColor: '#eef0f4  ',
    color: '#307fc1  ',
    border: '1px solid #307fc1',
    minWidth: '100px !important',
    boxShadow: 'none',
    '&:hover': {
        '& svg': { color: '#ffffff' },
        backgroundColor: '#307fc1',
        color: '#ffffff',
    },
}


export const ResetToDefault = ({ handleReset, resetting }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const button = (
        <Button.Rounded
            styles={resetButtonStyles}
            loading={resetting}
            disabled={resetting}
            onClick={() => handleReset()}
        >
            {t('ResetAllPreferencesToDefault')}
        </Button.Rounded>
    )

    return (
        <Box className={classes.resetButtonContainer}>
            {button}

            <Box color="#848992" fontSize="13px" display='flex' alignItems='center'>
                {t('ResetNotificationsSettings')}
            </Box>
        </Box>
    )
}
