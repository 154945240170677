import React from 'react'
import { EditDelegationForm, Button, Modal } from 'components'
import { useTranslation } from 'react-i18next'
import { useModalState } from 'packages/core'
import { Box } from '@mui/material'
import { Tooltip } from 'packages/eid-ui'

const EditDelegateButton = ({ data, attribute }) => {
    const { t } = useTranslation()
    const [modalOpen, openModal, closeModal] = useModalState()

    const checkIfDisabled = () => {
        try {
            if (attribute?.disabledFlagKey) {
                const isFlagActive = data[attribute.disabledFlagKey]
                if (isFlagActive === false) {
                    return true
                }
            }
        } catch (err) {}

        return false
    }

    return (
        <>
            {modalOpen && (
                <Modal
                    title={t('TypeDelegationToPerson', {
                        type: data.approvalFlowStepFriendlyName,
                        name: data.delegateeFriendlyName,
                    })}
                    open={modalOpen}
                    onClose={closeModal}
                >
                    <EditDelegationForm
                        delegatee={data}
                        attributeData={attribute}
                        onSave={closeModal}
                        onCancel={closeModal}
                    />
                </Modal>
            )}
            <Tooltip
                title={checkIfDisabled() ? t('UserDisabled') : ''}
            >
                <Box>
                    <Button.Edit
                        disabled={checkIfDisabled()}
                        styles={
                            checkIfDisabled()
                                ? {
                                      backgroundColor: '#aab0b4 !important',
                                      borderColor: '#aab0b4 !important',
                                      color: '#ffffff !important',
                                  }
                                : {}
                        }
                        onClick={openModal}
                    >
                        {t('Edit')}
                    </Button.Edit>
                </Box>
            </Tooltip>
        </>
    )
}

export default EditDelegateButton
