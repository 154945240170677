import { Box, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { mobileScreenWidth } from 'utils'

export const styles = {
    peopleSearch: {
        '& > div': {
            border: 'solid 1px #ebebed',
            '&:hover': {
                borderColor: '#307fc1',
            },
            padding: '1.5px !important',
            '& > div': {
                right: '0px !important',
                pointerEvents: 'none',
            },
            '& > input': {
                height: '35px',
                cursor: 'pointer',
                width: 'auto !important',
            },
        },
    },
    cancelButton: {
        backgroundColor: '#ffffff !important',
        color: '#307fc1',
        boxShadow: 'none',
        border: '1px solid #307fc1',
    },
    checkIcon: {
        position: 'absolute',
        right: '10px',
    },
    listLabel: {
        position: 'absolute',
        top: '-24px',
        left: 5,
        fontSize: '10px',
        color: '#b4b4b4',
        fontWeight: 'bold',
    },
    skeleton: {
        margin: '10px 0px',
        width: '30%',
        borderRadius: '3px',
    },
}

export const useStyles = makeStyles(() => ({
    formLabelMargin: {
        marginRight: '30px',
    },

    radio: {
        padding: '0px 12px 0px 0px',
    },

    checked: {
        '&, & + $label': {
            color: '#000000',
        },
    },
    label: {
        color: '#767676',
    },

    transferListsContainer: {
        display: 'flex',
        width: '100%',
        [`@media (min-width:${mobileScreenWidth})`]: {
            height: '290px',
        },
    },
    listContainer: {
        height: '260px',
        overflow: 'visible',
        width: '40%',
        [`@media (max-width:${mobileScreenWidth})`]: {
            width: '100%',
            height: '250px',
        },
    },
    buttonsContainer: {
        width: '20%',
        minWidth: '124px',
        height: '100%',

        padding: '0px 10px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        [`@media (max-width:${mobileScreenWidth})`]: {
            width: '100%',
            padding: '20px 0px',
            flexDirection: 'row',
            '& >div:first-child': {
                marginTop: '0px',
            },
        },
    },

    dateFilterContainer: {
        [`@media (max-width:${mobileScreenWidth})`]: {
            width: '100%',
        },
    },
}))

export const ItemContainer = styled(Box)({
    padding: '15px 0px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

export const reducers = (state, { type, payload }) => {
    let newItems
    switch (type) {
        case 'SET_START_DATE':
            return { ...state, startDate: payload }

        case 'SET_END_DATE':
            return { ...state, endDate: payload }

        case 'SET_PEOPLE':
            return { ...state, people: payload }

        case 'SET_DELEGATE_FOREVER':
            return {
                ...state,
                endDate: null,
                activeOnlyIfOutOfOffice: false,
                delegateForever: payload,
            }
        case 'SET_OUT_OF_OFFICE':
            return {
                ...state,
                delegateForever: false,
                activeOnlyIfOutOfOffice: payload,
            }

        case 'CLEAR_STATE':
            return {
                ...payload,
            }

        case 'ADD_ITEMS':
            newItems = []
            //payload here is the items to add
            for (let i = 0; i < payload.length; i++) {
                const item = payload[i]
                const itemIndex = state.types.findIndex((i) => i.id === item.id)

                if (itemIndex === -1) {
                    newItems.push(item)
                }
            }

            return {
                ...state,
                types: [...state.types, ...newItems],
            }

        case 'REMOVE_ITEMS':
            newItems = [...state.types]

            for (let i = 0; i < payload.length; i++) {
                const item = payload[i]
                const itemIndex = newItems.findIndex((i) => i.id === item.id)

                if (itemIndex !== -1) {
                    newItems.splice(itemIndex, 1)
                }
            }

            return {
                ...state,
                types: newItems,
            }
        case 'REMOVE_ALL_ITEMS':
            return {
                ...state,
                types: payload,
            }
        default:
            return state
    }
}
