import React from 'react'
import { Box, styled } from '@mui/material'
import { Layout, ScrollToTopButton } from 'packages/eid-ui'
import Header from './Header'
import GlobalMenu from './GlobalMenu'
import ScrollToTop from 'react-scroll-up'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { useApplicationSettings } from 'hooks'
import { useIsSmallScreen } from 'packages/core'
import { headerHeight, subHeaderHeight } from 'utils'

const SubHeaderContainer = styled(Box)(
    ({ hideHeader, hideSideNavigation, isSmallScreen }) => ({
        position: 'fixed',
        top: hideHeader ? 0 : headerHeight,
        width: '100%',
        maxWidth: '1920px',
        height: subHeaderHeight,
        display: 'flex',
        alignItems: 'end',
        paddingTop: '0',
        paddingRight: isSmallScreen ? '0px' : '20px',
        paddingLeft: isSmallScreen
            ? '0px'
            : hideSideNavigation
            ? '25px'
            : '90px',
        backgroundColor: 'transparent',
        zIndex: 1099,

        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: 'rgb(238, 240, 244)',
            position: 'absolute',
            left: 0,
            top: '-4px',
            width: '100%',
            height: 'calc(100% + 1px)',
        },
    }),
)

const AppLayout = (props) => {
    const { subHeader, children } = props
    const { data: appConfig } = useApplicationSettings()
    const isSmallScreen = useIsSmallScreen()
    const { t } = useTranslation()

    const hideHeader = appConfig.style.header.hide
    const hideSideNavigation = appConfig.style.hideSideNavigation

    const styles = {
        rootStyles: {
            position: 'relative',
            paddingBottom: '30px',
            ...(hideHeader && { inset: '0px !important' }),
        },
        scrollButton: {
            bottom: 20,
            right: 20,
            zIndex: 5,
        },
        contentContainerStyles: {
            ...(!isSmallScreen && hideSideNavigation && { marginLeft: '17px' }),
        },
    }

    const scrollToTopComponent = (
        <ScrollToTop showUnder={160} style={styles.scrollButton}>
            <ScrollToTopButton
                size="medium"
                label={t('BackToTop')}
            />
        </ScrollToTop>
    )
    return (
        <Layout
            header={!hideHeader && <Header />}
            globalMenu={<GlobalMenu hideSideNavigation={hideSideNavigation} />}
            subHeader={
                <SubHeaderContainer
                    hideHeader={hideHeader}
                    hideSideNavigation={hideSideNavigation}
                >
                    {subHeader}
                </SubHeaderContainer>
            }
            children={children}
            scrollToTopComponent={
                config.SHOW_SCROLL_TO_TOP && scrollToTopComponent
            }
            rootStyles={styles.rootStyles}
            contentContainerStyles={styles.contentContainerStyles}
        />
    )
}

export default AppLayout
