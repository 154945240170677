import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withRouter } from 'react-router'
import {
    Card,
    CardContent,
    CardActions,
    Box,
    Avatar,
    Typography,
    styled,
    Link,
    Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import appConfig from 'config'
import { TextFormatter } from 'components'
import { MIDPathToImage } from 'packages/core/helpers'
import { useTranslation } from 'react-i18next'

import * as DynamicComponents from 'components/DynamicComponents'
import { Icon } from 'packages/eid-icons'
import { isNilOrEmpty } from 'packages/core'
import { Divider } from 'packages/eid-ui'

const colors = {
    primary: '#307fc1',
    grey: '#b4b4b4',
    border: '#f2f2f3',
}

const MyCard = styled(Card)({
    margin: 'auto',
    padding: '0px 10px 5px 10px',
})

const useStyles = makeStyles((theme) => ({
    header: {
        padding: '10px 8px 10px 8px',
        borderBottom: '1px solid ' + colors.border,
    },
    hr: {
        width: '92%',
        opacity: '0.4',
        borderColor: '#f5f5f5',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    content: {
        padding: '16px 0px',
        borderBottom: '1px solid ' + colors.border,
    },
    actions: {
        paddingBottom: '12px',
        paddingTop: '24px',
    },
    noBorder: {
        border: 'none',
    },
    avatar: {
        width: '54px',
        height: '54px',
    },

    table: {
        '& th': {
            color: colors.grey,
            fontWeight: 'normal',
            padding: '0px 5px',
        },
        '& td': {
            padding: '0px 5px',
        },
        tableLayout: 'fixed',
        width: '100%',
    },
    name: {
        fontSize: '15px',
        textAlign: 'left',
    },
    blueBold: {
        fontWeight: 'bold',
        color: '#307fc1',
        lineHeight: '1',
    },
    blue: {
        color: '#307fc1',
    },
    fieldsPadding: {
        padding: '0 7px',
    },
    fieldBlock: {
        '& div': {
            textAlign: 'left',
        },
        '&:first-child': {
            paddingRight: '10px',
            '& div': {
                float: 'right',
            },
        },
        '&:last-child': {
            paddingLeft: '10px',
        },
    },
}))

function DelegationCard({
    data,
    handleCardChecked,
    selectAll,
    rowSelection,
    setSelectAll,
    checked,
    hideActions,
    showDelegator,
    attributes,
}) {
    const {
        delegateePathToImage,
        delegatorPathToImage,
        approvalFlowStepFriendlyName,
        delegateeFriendlyName,
        delegatorFriendlyName,
        activeOnlyIfOutOfOffice,
    } = data

    const [isCardSelected, setIsCardSelected] = useState(null)
    useEffect(() => {
        setIsCardSelected(checked || selectAll)
    }, [selectAll, checked])
    const classes = useStyles()
    const { t } = useTranslation()

    const pathToImage = showDelegator
        ? delegatorPathToImage
        : delegateePathToImage

    const friendlyName = showDelegator
        ? delegatorFriendlyName
        : delegateeFriendlyName

    function getDetailsTable(dataMap) {
        return (
            <table className={classes.table}>
                <thead>
                    <tr>
                        {Object.keys(dataMap).map((k, index) => {
                            return (
                                <th
                                    key={'dm-h-k' + index}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Typography
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '8px',
                                        }}
                                        variant="subtitle1"
                                    >
                                        {k}
                                    </Typography>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {Object.keys(dataMap).map((k, index) => {
                            return (
                                <td
                                    key={'dm-v-k' + index}
                                    style={{ textAlign: 'left' }}
                                    className={dataMap[k].class}
                                >
                                    <Typography
                                        className={dataMap[k].class}
                                        variant="body2"
                                    >
                                        {dataMap[k].value}
                                    </Typography>
                                </td>
                            )
                        })}
                    </tr>
                </tbody>
            </table>
        )
    }

    const delegationCard = (
        <>
            <Box
                style={{
                    display: 'flex',
                    paddingTop: '16px',
                    marginBottom: '8px',
                }}
            >
                {!hideActions && (
                    <>
                        {rowSelection && (
                            <Checkbox
                                checked={isCardSelected}
                                icon={<Icon name="CheckBox" />}
                                checkedIcon={<Icon name="CheckedBox" />}
                                onClick={() => {
                                    if (isNilOrEmpty(isCardSelected)) {
                                        setIsCardSelected(true)
                                    } else {
                                        setIsCardSelected((s) => !s)
                                        if (selectAll) {
                                            setSelectAll(false)
                                        }
                                    }
                                    handleCardChecked(data)
                                }}
                            />
                        )}
                    </>
                )}

                <div style={{ margin: '0px 14px 0px 10px' }}>
                    <Avatar
                        src={
                            pathToImage &&
                            MIDPathToImage(appConfig.BASE_EID_URL, pathToImage)
                        }
                        aria-label="recipe"
                        className={classes.avatar}
                    />
                </div>
                <Box className={classes.fieldBlock}>
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('PersonName')}
                        </Typography>
                        <Typography variant="body2">
                            <Link
                                className={clsx(classes.blueBold, classes.name)}
                                component="button"
                            >
                                <TextFormatter
                                    value={friendlyName}
                                    maxTextWidth={150}
                                />
                            </Link>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    style={{ marginLeft: '15px' }}
                    className={classes.fieldBlock}
                >
                    <Box>
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                                fontSize: '8px',
                            }}
                            variant="subtitle1"
                        >
                            {t('ApprovalStep')}
                        </Typography>
                        <Typography
                            className={clsx(classes.blue, classes.name)}
                            variant="body2"
                        >
                            <TextFormatter
                                value={approvalFlowStepFriendlyName}
                                maxTextWidth={250}
                            />
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider style={{ backgroundColor: '#efeff1' }} />
            <CardContent className={classes.content}>
                {getDetailsTable({
                    [t('StartDate')]: {
                        value: (
                            <DynamicComponents.CellDateTime
                                data={data}
                                attribute={{ name: 'startDateUtc' }}
                                multiline={true}
                            />
                        ),
                        class: '',
                    },
                    [t('EndDate')]: {
                        value: (
                            <DynamicComponents.CellDateTime
                                data={data}
                                attribute={{ name: 'endDateUtc' }}
                                multiline={true}
                            />
                        ),
                        class: '',
                    },
                    [t('OnlyWhenOutOfOffice')]: {
                        value: (
                            <TextFormatter
                                value={activeOnlyIfOutOfOffice}
                                maxTextWidth={110}
                            />
                        ),
                        class: '',
                    },
                })}
            </CardContent>
            {!hideActions && (
                <CardActions disableSpacing className={classes.actions}>
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                            padding: '5px 5px',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '16px',
                        }}
                    >
                        <DynamicComponents.EditDelegateButton data={data} />

                        <DynamicComponents.DeleteDelegateButton
                            data={data}
                            attribute={attributes.find(
                                (x) => x.name === 'deleteButton',
                            )}
                        />
                    </div>
                </CardActions>
            )}
        </>
    )

    return <MyCard className={classes.card}>{delegationCard}</MyCard>
}

DelegationCard.propTypes = {
    data: PropTypes.object.isRequired,
}

export default withRouter(DelegationCard)
