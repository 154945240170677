import React from 'react'
import { Card, Box, Link, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { EditIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { Slope } from 'images'
import config from 'config'
const useStyles = makeStyles(() => ({
    root: (props) => ({
        overflow: 'visible',
        maxWidth: props.width && props.width,
    }),
}))

const useStyles2 = makeStyles({
    root: { display: 'flex', justifyContent: 'center' },

    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '-2.285em',
        backgroundColor: '#eef0f4',
        height: '68px',
        width: '95px',
        borderRadius: '50%',
        alignItems: 'center',
    },

    link: {
        height: '45px',
        width: '45px',
        backgroundColor: '#FFF',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 5px 4px 0 rgba(0, 0, 0, 0.1)',
    },
})

const GenericCard = ({
    editIcon,
    width,
    onClick,
    editable,
    helpText,
    children,
    showEditOption,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({
        width,
    })

    const classes2 = useStyles2()
    if (editIcon) {
        var Icon = React.createElement(editIcon, {
            color: editable ? '#307fc1' : '#b4b4b4',
        })
    }
    return (
        <Card className={classes.root}>
            {children}

            {showEditOption && (
                <Box className={classes2.root}>
                    <img
                        style={{ position: 'absolute' }}
                        src={`${config.APP_SUBPATH}${Slope}`}
                        alt="Slope"
                    />
                    <Box className={classes2.iconBox}>
                        <Tooltip
                            title={
                                helpText
                                    ? helpText
                                    : editable
                                    ? t('ClickToEdit')
                                    : t(
                                          'YouDontHavePermissionToEdit',
                                      )
                            }
                        >
                            <Link
                                className={classes2.link}
                                component="button"
                                onClick={onClick}
                            >
                                {editIcon ? (
                                    Icon
                                ) : (
                                    <EditIcon
                                        color={editable ? '#307fc1' : '#b4b4b4'}
                                    />
                                )}
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
            )}
        </Card>
    )
}

export default GenericCard
