import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useOrgChart } from 'hooks'
import { FC } from 'react'
import ReactFlow, { Controls } from 'react-flow-renderer'
import { MyIdentityLayout, SubHeader } from 'components'
import makeStyles from '@mui/styles/makeStyles';
import { PersonNode } from './PersonNode'
import { OrgChartSkeleton } from './OrgChartSkeleton'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: '65px',
            width: '100%',
            height: '90vh',
        },
    }),
)

export const nodeTypes = {
    person: PersonNode,
}

export const OrgChart: FC<{ personGuid: string }> = (props) => {
    const classes = useStyles()

    const { data, isLoading } = useOrgChart(props.personGuid)

    return (
        <MyIdentityLayout subHeader={<SubHeader showBackButtonOnly />}>
            {isLoading && (
                <Box className={classes.root}>
                    <OrgChartSkeleton />
                </Box>
            )}

            {data && (
                <Box className={classes.root}>
                    {/*@ts-ignore*/}

                    <ReactFlow
                        nodesDraggable={false}
                        nodesConnectable={false}
                        nodeTypes={nodeTypes}
                        elements={data}
                    >
                        <Controls
                            style={{
                                bottom: 'unset',
                                left: 'unset',
                                top: '32px',
                                right: '32px',
                            }}
                        />
                    </ReactFlow>
                </Box>
            )}
        </MyIdentityLayout>
    )
}
