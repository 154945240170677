import React, { useEffect } from 'react'
import { Loader, MaintenancePage } from 'packages/eid-ui'
import { useApplicationSettings } from 'hooks'
import config from 'config'
import { isAppRunningInIframe, isNilOrEmpty } from 'packages/core'
import { useAppState } from 'appContext'

const useLoadApplicationSettings = () => {
    let settingsOverride = config?.UI_SETTINGS_OVERRIDE
    const [, dispatch] = useAppState()

    if (isAppRunningInIframe()) {
        settingsOverride = 'ServiceNow'
    }
    const { data, isLoading } = useApplicationSettings(settingsOverride)
    useEffect(() => {
        if (data) {
            dispatch({
                type: 'SET_APP_CONFIG',
                payload: data,
            })
        }
    }, [data])

    return { isReady: !isNilOrEmpty(data), isLoading }
}

const withRequiredApplicationSettings = (ChildComponent) => (props) => {
    const { isLoading, isReady } = useLoadApplicationSettings()

    if (isLoading) {
        return <Loader />
    }

    if (!isReady) {
        return (
            <MaintenancePage
                title="Under Maintenance "
                description="Making things better, please stand by."
                linkText="Take me to the home page"
            />
        )
    }

    return <ChildComponent {...props} />
}

export default withRequiredApplicationSettings
