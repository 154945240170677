import { styled, List, ListItem, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'

const StyledList = styled(List)({
    width: '100%',
    height: '100%',
    padding: '0px 4px',
    border: '1px solid #EBEBED',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    overflow: 'auto',
})

const useListItemStyles = makeStyles({
    root: {
        display: 'block',
        paddingLeft: '8px',
        paddingRight: '8px',
        color: '#5d6870',
        fontSize: '14px',
        lineHeight: 1.16,
        borderRadius: '5px',
        margin: '4px 0px',
        '&:hover': {
            backgroundColor: '#f8f8f8',
            cursor: 'pointer',
        },
    },
    selected: {
        backgroundColor: '#307fc1 !important',
        color: '#ffffff',
    },
    itemName: {
        width: '210px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
    },
    itemDescription: {
        fontSize: '12px',
        width: '210px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
    },
})

const styles = {
    checkIcon: {
        position: 'absolute',
        right: '10px',
    },
}

const SelectionList = ({ options, selectedItems, onChange, variant }) => {
    const listItemClasses = useListItemStyles()

    const handleClick = (item) => {
        const itemIndex = selectedItems.findIndex((i) => i.id === item.id)

        let newSelected = []

        if (itemIndex === -1) {
            newSelected = newSelected.concat(selectedItems, item)
        } else if (itemIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1))
        } else if (itemIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1))
        } else if (itemIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, itemIndex),
                selectedItems.slice(itemIndex + 1),
            )
        }

        onChange(newSelected)
    }

    const isSelected = (item) =>
        selectedItems.findIndex((i) => i.id === item.id) !== -1

    return (
        <>
            <StyledList>
                {options.map((item) => (
                    <Tooltip
                        title={
                            <>
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {item?.name}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                    }}
                                >
                                    {item?.localizedDescription}
                                </Typography>
                            </>
                        }
                    >
                        <ListItem
                            classes={listItemClasses}
                            key={item.name}
                            onClick={() => handleClick(item)}
                            selected={isSelected(item)}
                        >
                            <>
                                <Box className={listItemClasses.itemName}>
                                    {item.name}

                                    {variant !== 'secondary' &&
                                        isSelected(item) && (
                                            <Icon
                                                name="Check"
                                                color="#ffffff"
                                                style={styles.checkIcon}
                                            />
                                        )}

                                    {variant === 'secondary' && (
                                        <Icon
                                            name="Check"
                                            color={
                                                isSelected(item)
                                                    ? '#ffffff'
                                                    : '#8b909a'
                                            }
                                            style={styles.checkIcon}
                                        />
                                    )}
                                </Box>
                                <Box
                                    className={listItemClasses.itemDescription}
                                    color={
                                        isSelected(item) ? '#ffffff' : '#8b909a'
                                    }
                                >
                                    {item.localizedDescription}
                                </Box>
                            </>
                        </ListItem>
                    </Tooltip>
                ))}
            </StyledList>
        </>
    )
}

export default SelectionList
