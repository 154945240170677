import React from 'react'
import { Box } from '@mui/material'
import { Button, Modal, NewDelegationForm } from 'components'
import { useTranslation } from 'react-i18next'
import { useModalState } from 'packages/core'
import NewCollaborationTaskDelegationForm from 'components/NewDelegationForm/CollaborationTaskDelegationForm'

const styles = {
    newDelegationButton: {
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        minWidth: '140px',
    },
}

const CreateNewDelegateButton = ({
    label,
    title,
    isCollaborationTask = false,
}) => {
    const { t } = useTranslation()
    const [modalOpen, openModal, closeModal] = useModalState()
    return (
        <>
            {modalOpen && (
                <Modal title={t(title)} open={modalOpen} onClose={closeModal}>
                    {isCollaborationTask ? (
                        <NewCollaborationTaskDelegationForm
                            onSave={closeModal}
                            onCancel={closeModal}
                        />
                    ) : (
                        <NewDelegationForm
                            onSave={closeModal}
                            onCancel={closeModal}
                        />
                    )}
                </Modal>
            )}
            <Box style={styles.newDelegationButton}>
                <Button.NewDelegation onClick={openModal}>
                    {t(label)}
                </Button.NewDelegation>
            </Box>
        </>
    )
}

export default CreateNewDelegateButton
