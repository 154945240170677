import React, { useState } from 'react'
import { createStyles, withStyles } from '@mui/styles'
import { InputBase, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'

const options = [
    { value: 'ooOffice', label: 'OoO' },
    { value: 'inOffice', label: 'InOffice' },
]

const StyledInput = withStyles((theme) =>
    createStyles({
        root: {
            marginTop: '30px',
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
            border: 'solid 1px #d8d8dd',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '6px 8px 6px 12px',
            minWidth: '12rem !important',
            minHeight: '0 !important',
            lineHeight: '1.2',
            color: '#3b454d',

            '&:focus': {
                borderRadius: 4,
                borderColor: 'transparent',
                boxShadow: '0 0 0 0.3rem rgba(0,123,255,.25)',
            },
        },
    }),
)(InputBase)

export const OutOfOffceSelector = (props: any) => {
    const { t } = useTranslation()

    const { handleChange, value }: any = props

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const onChange = (event: SelectChangeEvent<string>) => {
        handleChange(event.target.value)
    }

    const menuProps: any = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }

    return (
        <>
            <Select
                variant="standard"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={value}
                onChange={onChange}
                MenuProps={{
                    ...menuProps,
                }}
                input={<StyledInput />}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {t(option.label)}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}
