import React, { useReducer, useState, useEffect } from 'react'
import {
    Box,
    FormControlLabel,
    Radio,
    Typography,
    Checkbox,
} from '@mui/material'
import { Skeleton } from '@mui/material';
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import PeopleSearch from '../PeopleSearch'
import DateTimeFilter from '../DateTimeFilter'
import Button from '../Button'
import SelectionList from './SelectionList'
import { isNilOrEmpty } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import moment from 'moment'
import { useDelegationTypes, useCreateDelegation } from 'hooks'
import { styles, useStyles, ItemContainer, reducers } from './utils'
import useSubcomponents from 'useSubcomponents'

const delegateAllTypesControlName =
    'MyIdentity-PermanentDelegations-DelegateAllTypes-Control'
const delegateSelectedTypesControlName =
    'MyIdentity-PermanentDelegations-DelegateSelectedTypes-Control'

const TypeSelectionControl = ({
    type,
    delegationTypes,
    setType,
    setItemsToAdd,
    dispatch,
}) => {
    const { t } = useTranslation()

    const { hasAccessToControl } = useSubcomponents()

    const hasDelegateAllAccess = hasAccessToControl(delegateAllTypesControlName)

    const hasDelegateSelectedAccess = hasAccessToControl(
        delegateSelectedTypesControlName,
    )

    const classes = useStyles()
    return hasDelegateAllAccess || hasDelegateSelectedAccess ? (
        <ItemContainer display="flex" flexDirection="column" marginLeft="12px">
            {hasDelegateAllAccess && (
                <FormControlLabel
                    data-protectedsubcomponent={delegateAllTypesControlName}
                    classes={{
                        label: classes.label,
                    }}
                    checked={type === 'all'}
                    label={t('AllTypes')}
                    labelPlacement="end"
                    control={
                        <Radio
                            icon={<Icon name="Radio" />}
                            classes={{
                                root: classes.radio,
                                checked: classes.checked,
                            }}
                            checkedIcon={<Icon name="RadioFilled" />}
                        />
                    }
                    onChange={() => {
                        setType('all')
                        setItemsToAdd([])
                        dispatch({
                            type: 'ADD_ITEMS',
                            payload: delegationTypes,
                        })
                    }}
                />
            )}

            {hasDelegateSelectedAccess && (
                <FormControlLabel
                    data-protectedsubcomponent={
                        delegateSelectedTypesControlName
                    }
                    classes={{
                        label: classes.label,
                    }}
                    checked={type === 'selectTypes'}
                    label={t('SelectTypes')}
                    labelPlacement="end"
                    control={
                        <Radio
                            icon={<Icon name="Radio" />}
                            checkedIcon={<Icon name="RadioFilled" />}
                            classes={{
                                root: classes.radio,
                                checked: classes.checked,
                            }}
                        />
                    }
                    onChange={() => {
                        setType('selectTypes')
                        dispatch({
                            type: 'REMOVE_ALL_ITEMS',
                            payload: [],
                        })
                    }}
                />
            )}
        </ItemContainer>
    ) : (
        <></>
    )
}

const NewDelegationForm = ({ onSave, onCancel }) => {
    const [state, dispatch] = useReducer(reducers, {
        people: [],
        startDate: moment().startOf('day').utc(),
        endDate: null,
        delegateForever: false,
        activeOnlyIfOutOfOffice: false,
        types: [],
    })

    const { t } = useTranslation()

    const classes = useStyles()

    const [type, setType] = useState(null)

    const [itemsToAdd, setItemsToAdd] = useState([])
    const [itemsToRemove, setItemsToRemove] = useState([])

    const { data: delegationTypes } = useDelegationTypes()

    const [createDelegation, { isLoading, isSuccess }] = useCreateDelegation()

    const { hasAccessToControl } = useSubcomponents()

    const hasOutOfOfficeAccess = hasAccessToControl(
        'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control',
    )

    const hasDelegateAllAccess = hasAccessToControl(delegateAllTypesControlName)

    const hasDelegateSelectedAccess = hasAccessToControl(
        delegateSelectedTypesControlName,
    )

    useEffect(() => {
        if (delegationTypes) {
            if (!hasDelegateAllAccess && hasDelegateSelectedAccess) {
                setType('selectTypes')
                dispatch({
                    type: 'REMOVE_ALL_ITEMS',
                    payload: [],
                })
            } else {
                setType('all')
                dispatch({
                    type: 'ADD_ITEMS',
                    payload: delegationTypes,
                })
            }
        }
    }, [delegationTypes])

    const handleCreateDelegation = () => {
        const dataToSubmit = {
            startDateUtc: state.startDate,
            endDateUtc: state.endDate,
            people: state.people.map((p) => p.id),
            types: state.types.map((t) => t.id),
        }
        if (hasOutOfOfficeAccess) {
            dataToSubmit.activeOnlyIfOutOfOffice = state.activeOnlyIfOutOfOffice
        }
        createDelegation(dataToSubmit)
    }

    useEffect(() => {
        if (isSuccess) onSave()
    }, [isSuccess, onSave])

    const endDateCheck =
        (!state.delegateForever && isNilOrEmpty(state.endDate)) ||
        state.startDate?.isAfter(state.endDate)

    const transferListButtons = delegationTypes && (
        <Box className={classes.buttonsContainer}>
            <Box marginTop="-8px">
                <Button.Add
                    onClick={() => {
                        dispatch({
                            type: 'ADD_ITEMS',
                            payload: itemsToAdd,
                        })
                        setItemsToAdd([])
                    }}
                    disabled={isNilOrEmpty(itemsToAdd)}
                >
                    {t('Add')}
                </Button.Add>

                <Button.AddAll
                    onClick={() => {
                        dispatch({
                            type: 'ADD_ITEMS',
                            payload: delegationTypes,
                        })
                        setItemsToAdd([])
                    }}
                    disabled={delegationTypes.length === state.types.length}
                >
                    {t('AddAll')}
                </Button.AddAll>
            </Box>

            <Box marginBottom="-8px">
                <Button.Remove
                    startIcon
                    onClick={() => {
                        dispatch({
                            type: 'REMOVE_ITEMS',
                            payload: itemsToRemove,
                        })
                        setItemsToRemove([])
                    }}
                    disabled={isNilOrEmpty(itemsToRemove)}
                >
                    {t('Remove')}
                </Button.Remove>

                <Button.RemoveAll
                    startIcon
                    disabled={isNilOrEmpty(state.types)}
                    onClick={() => {
                        dispatch({
                            type: 'REMOVE_ALL_ITEMS',
                            payload: [],
                        })
                        setItemsToRemove([])
                    }}
                >
                    {t('RemoveAll')}
                </Button.RemoveAll>
            </Box>
        </Box>
    )

    return <>
        {!delegationTypes && (
            <>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={16}
                    style={styles.skeleton}
                />
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={16}
                    style={styles.skeleton}
                />
            </>
        )}

        {delegationTypes && (
            <TypeSelectionControl
                type={type}
                delegationTypes={delegationTypes}
                setType={setType}
                setItemsToAdd={setItemsToAdd}
                dispatch={dispatch}
            />
        )}

        {type === 'selectTypes' && (
            <ItemContainer className={classes.transferListsContainer}>
                <Box className={classes.listContainer}>
                    <SelectionList
                        options={delegationTypes}
                        onChange={(items) => setItemsToAdd(items)}
                        selectedItems={itemsToAdd}
                    />
                </Box>

                {transferListButtons}

                <Box
                    className={classes.listContainer}
                    position="relative"
                    overflow="visible"
                >
                    <Typography style={styles.listLabel}>
                        {t('SelectedTypes')}
                    </Typography>

                    <SelectionList
                        options={state.types}
                        onChange={(items) => setItemsToRemove(items)}
                        selectedItems={itemsToRemove}
                        variant="secondary"
                    />
                </Box>
            </ItemContainer>
        )}

        <ItemContainer>
            <PeopleSearch
                url={'/api/v1/Delegation/approvers'}
                endAdornmentComponent={() => <></>}
                label={t('PersonName')}
                placeholder={t('SelectPerson')}
                onChange={(_, value) => {
                    dispatch({
                        type: 'SET_PEOPLE',
                        payload: value,
                    })
                }}
                rootStyleProps={styles.peopleSearch}
                value={state.people}
                multiple
            />
        </ItemContainer>

        <ItemContainer display="flex" marginLeft="12px">
            <FormControlLabel
                classes={{
                    root: classes.formLabelMargin,
                    label: classes.label,
                }}
                control={
                    <Checkbox
                        checked={state.delegateForever}
                        icon={<Icon name="CheckBox" />}
                        checkedIcon={<Icon name="CheckedBox" />}
                        classes={{
                            checked: classes.checked,
                        }}
                        onChange={(e) =>
                            dispatch({
                                type: 'SET_DELEGATE_FOREVER',
                                payload: e.target.checked,
                            })
                        }
                    />
                }
                label={t('DelegateForever')}
            />
            {hasOutOfOfficeAccess && (
                <FormControlLabel
                    data-protectedsubcomponent={
                        'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control'
                    }
                    classes={{ label: classes.label }}
                    control={
                        <Checkbox
                            classes={{ checked: classes.checked }}
                            checked={state.activeOnlyIfOutOfOffice}
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            onChange={(e) =>
                                dispatch({
                                    type: 'SET_OUT_OF_OFFICE',
                                    payload: e.target.checked,
                                })
                            }
                        />
                    }
                    label={t('OnlyWhenOutOfOffice')}
                />
            )}
        </ItemContainer>

        <ItemContainer display="flex" marginLeft="-8px">
            <Box padding="0px 8px" className={classes.dateFilterContainer}>
                <DateTimeFilter
                    disablePast={true}
                    margin="normal"
                    label={t('StartDate')}
                    placeholder={t('SelectDate')}
                    onChange={(date) => {
                        let dateToSet
                        if (!isNilOrEmpty(date)) {
                            dateToSet = moment(date)
                                .clone()
                                .startOf('day')
                                .utc()
                        }
                        dispatch({
                            type: 'SET_START_DATE',
                            payload: dateToSet,
                        })
                    }}
                    value={
                        isNilOrEmpty(state.startDate)
                            ? null
                            : state.startDate.clone().local()
                    }
                />
            </Box>

            {!state.delegateForever && (
                <Box
                    padding="0px 8px "
                    className={classes.dateFilterContainer}
                >
                    <DateTimeFilter
                        minDate={
                            !isNilOrEmpty(state.startDate) &&
                            state.startDate.clone().local()
                        }
                        disabled={state.delegateForever}
                        label={t('EndDate')}
                        placeholder={t('SelectDate')}
                        onChange={(date) => {
                            let dateToSet
                            if (!isNilOrEmpty(date)) {
                                dateToSet = moment(date)
                                    .clone()
                                    .endOf('day')
                                    .subtract(1, 'minutes')
                                    .utc()
                            }
                            dispatch({
                                type: 'SET_END_DATE',
                                payload: dateToSet,
                            })
                        }}
                        value={
                            isNilOrEmpty(state.endDate)
                                ? null
                                : state.endDate.clone().local()
                        }
                    />
                </Box>
            )}
        </ItemContainer>

        <ItemContainer
            display="flex"
            marginLeft="-8px"
            alignItems="center"
            flexDirection="row !important"
            flexWrap="wrap"
        >
            <Tooltip
                title={
                    state.types.length === 0 ||
                    isNilOrEmpty(state.startDate) ||
                    isNilOrEmpty(state.people) ||
                    endDateCheck
                        ? t('RequiredFieldsMissing')
                        : ''
                }
            >
                <Box>
                    <Button.Rounded
                        loading={isLoading}
                        styles={{ minWidth: '144px !important' }}
                        disabled={
                            state.types.length === 0 ||
                            isNilOrEmpty(state.startDate) ||
                            isNilOrEmpty(state.people) ||
                            endDateCheck ||
                            isLoading
                        }
                        onClick={() => {
                            handleCreateDelegation()
                        }}
                    >
                        {t('CreateDelegation')}
                    </Button.Rounded>
                </Box>
            </Tooltip>

            <Button.Rounded onClick={onCancel} styles={styles.cancelButton}>
                {t('Cancel')}
            </Button.Rounded>
        </ItemContainer>
    </>;
}

export default NewDelegationForm
