import React from 'react'
import DesktopHeader from './DesktopHeader'
import { useIsSmallScreen } from 'packages/core'
import MobileHeader from './MobileHeader'
import { useApplicationSettings } from 'hooks'

const Header = ({ handleSearch }) => {
    const { data: appConfig } = useApplicationSettings()
    const headerStyleConfig = appConfig.style.header

    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <MobileHeader handleSearch={handleSearch} config={headerStyleConfig} />
    ) : (
        <DesktopHeader handleSearch={handleSearch} config={headerStyleConfig} />
    )
}

export default Header
