import { useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import { HorizontalTabs, Loader } from 'packages/eid-ui'
import {
    useAllNotifications,
    useCurrentPerson,
    useResetEventEmailsNotificationSettings,
    useUpdateGlobalNotificationSettings,
    useUpdateNotificationStatus,
    useResetDigestEmailsNotificationPreferences,
} from 'hooks'
import { MyIdentityLayout, SubHeader } from 'components'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'ramda'
import { useConfiguration } from 'appConfiguration'
import { useIsSmallScreen } from 'packages/core'
import { useStyles } from './utils'
import { DigestEmails } from './DigestEmails'
import { ResetToDefault } from './ResetToDefault'
import { NotificationsSwitch } from './NotificationsSwitch'
import { NotificationsSection } from './NotificationsSection'
import { Icon } from 'packages/eid-icons'
import { useAuthState } from 'packages/core/auth'

const subHeaderHeight = 75

const Tabs = styled(HorizontalTabs)({
    '& .MuiTabs-flexContainer': {
        height: '61px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#307fc1',
    },
    color: '#307fc1',
    fontWeight: 'normal !important',

    '& .MuiTab-textColorInherit.Mui-selected': {
        color: '#000000 !important',
        fontWeight: 'bold',
    },
    borderBottom: 'solid 1px #d8dadd',
})

const Notifications = () => {
    const { data: notifications, isLoading } = useAllNotifications()
    const [
        updateNotificationSetting,
        { isLoading: updatingNotificationStatus },
    ] = useUpdateNotificationStatus()

    const [itemToUpdate, setItemToUpdate] = useState(null)

    const handleUpdateNotificationSetting = (notification, active) => {
        setItemToUpdate(notification)
        updateNotificationSetting({
            id: notification.id,
            active: active,
        })
    }

    const participantTypeNotificationSettings =
        notifications &&
        groupBy((x) => x.localizedParticipantTypeFriendlyName, notifications)

    if (isLoading || !participantTypeNotificationSettings) {
        return (
            <Box width="100%" padding="20px">
                <Loader margin="auto" />
            </Box>
        )
    }

    return (
        <>
            {notifications &&
                participantTypeNotificationSettings &&
                Object.keys(participantTypeNotificationSettings).map(
                    (participantType) => (
                        <NotificationsSection
                            key={participantType}
                            section={participantType}
                            notifications={
                                participantTypeNotificationSettings[
                                    participantType
                                ]
                            }
                            handleUpdate={handleUpdateNotificationSetting}
                            updatingItem={
                                updatingNotificationStatus
                                    ? itemToUpdate
                                    : false
                            }
                        />
                    ),
                )}
        </>
    )
}

export const NotificationsPage = ({ history }) => {
    const { t } = useTranslation()
    const [{ currentUserId }] = useAuthState()
    const {
        hasAccessToDetailedNotificationSettings,
        canSeeDigestEmailNotificationSettings,
        canSeeFunctionsAndRisksNotificationSettings,
    } = useConfiguration()
    let defaultActiveTab

    if (hasAccessToDetailedNotificationSettings) {
        defaultActiveTab = 'eventEmails'
    } else if (canSeeFunctionsAndRisksNotificationSettings) {
        defaultActiveTab = 'riskNotifications'
    } else if (canSeeDigestEmailNotificationSettings) {
        defaultActiveTab = 'digestEmails'
    } else {
        defaultActiveTab = ''
    }

    const [activeTab, setActiveTab] = useState(defaultActiveTab)

    const classes = useStyles()
    const { data: personData } = useCurrentPerson()
    const [showNotifications, setShowNotifications] = useState(
        !personData?.disableNotifications,
    )
    const [
        updateNotificationSettings,
        { isLoading: updatingNotificationSettings },
    ] = useUpdateGlobalNotificationSettings(currentUserId)

    const handleUpdate = () => {
        let data = { ...personData }
        let disableNotifications = !data.disableNotifications
        setShowNotifications((pre) => !pre)
        updateNotificationSettings({
            disableNotifications: disableNotifications,
        })
    }
    const isSmallScreen = useIsSmallScreen()
    const backButton = (
        <Icon
            name="BackButton"
            style={{
                cursor: 'pointer',
                fill: '#307fc1',
            }}
            onClick={() => {
                history.goBack()
            }}
        />
    )
    const subHeaderContent = (
        <Box className={classes.subHeaderContent}>
            {backButton}

            <Box className={classes.pageTitleContainer}>
                {t('EmailNotifications')}
            </Box>
        </Box>
    )

    const [
        resetEventEmailNotificationSettings,
        { isLoading: resettingEventEmailNotificationSettings },
    ] = useResetEventEmailsNotificationSettings()

    const [
        resetDigestNotificationSettings,
        { isLoading: resettingDigestNotificationSettings },
    ] = useResetDigestEmailsNotificationPreferences()

    const handleReset = () => {
        if (hasAccessToDetailedNotificationSettings) {
            resetEventEmailNotificationSettings()
        }

        if (
            canSeeDigestEmailNotificationSettings ||
            canSeeFunctionsAndRisksNotificationSettings
        ) {
            resetDigestNotificationSettings()
        }
    }

    const resettingNotificationSettings =
        resettingEventEmailNotificationSettings ||
        resettingDigestNotificationSettings

    const showResetButton =
        hasAccessToDetailedNotificationSettings ||
        canSeeDigestEmailNotificationSettings ||
        canSeeFunctionsAndRisksNotificationSettings

    return (
        <MyIdentityLayout
            subHeader={
                <Box
                    className={classes.pageContainer}
                    paddingY="0px !important"
                >
                    <SubHeader subHeaderButton={subHeaderContent} />
                </Box>
            }
        >
            <Box
                paddingX="10px"
                paddingTop={`${subHeaderHeight}px`}
                paddingBottom={'20px'}
            >
                <Box className={classes.pageContainer}>
                    {!personData && (
                        <Box width="100%" padding="40px">
                            <Loader margin="auto" />
                        </Box>
                    )}

                    {personData && (
                        <>
                            <NotificationsSwitch
                                active={showNotifications}
                                handleUpdate={handleUpdate}
                                loading={updatingNotificationSettings}
                                classes={classes}
                            />

                            {showResetButton && (
                                <Box
                                    width="100%"
                                    display="flex"
                                    mt={3}
                                    px="10px"
                                >
                                    <ResetToDefault
                                        handleReset={handleReset}
                                        resetting={
                                            resettingNotificationSettings
                                        }
                                    />
                                </Box>
                            )}

                            <Box my="26px">
                                <Tabs
                                    variant={
                                        isSmallScreen
                                            ? 'scrollable'
                                            : 'standard'
                                    }
                                    scrollButtons={isSmallScreen ? 'on' : 'off'}
                                    value={activeTab}
                                    onChange={(_, value) => setActiveTab(value)}
                                >
                                    {hasAccessToDetailedNotificationSettings && (
                                        <Tabs.Tab
                                            value="eventEmails"
                                            label={t('EventEmails')}
                                            data-protectedsubcomponent={hasAccessToDetailedNotificationSettings}
                                        />
                                    )}

                                    {canSeeFunctionsAndRisksNotificationSettings && (
                                        <Tabs.Tab
                                            value="riskNotifications"
                                            label={t(
                                                'FunctionsAndRisksReports',
                                            )}
                                            data-protectedsubcomponent={canSeeFunctionsAndRisksNotificationSettings}
                                        />
                                    )}

                                    {canSeeDigestEmailNotificationSettings && (
                                        <Tabs.Tab
                                            value="digestEmails"
                                            label={t('DigestEmails')}
                                            data-protectedsubcomponent={canSeeDigestEmailNotificationSettings}
                                        />
                                    )}
                                </Tabs>
                            </Box>

                            {activeTab === 'eventEmails' && (
                                <>
                                    {showNotifications &&
                                    updatingNotificationSettings ? (
                                        <Loader margin="auto" />
                                    ) : (
                                        showNotifications && (
                                            <Notifications
                                                loading={
                                                    updatingNotificationSettings
                                                }
                                            />
                                        )
                                    )}
                                </>
                            )}

                            {activeTab === 'digestEmails' && (
                                <DigestEmails type={'MyTasks'} />
                            )}

                            {activeTab === 'riskNotifications' && (
                                <DigestEmails type={'RiskManagement'} />
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </MyIdentityLayout>
    )
}
