import React from 'react'
import moment from 'moment'
import { Box } from '@mui/material'

const CellDateTime = ({ attribute, data, multiline = false }) => {
    const value = data[attribute.name]
    return (
        <>
            {value ? (
                !multiline ? (
                    moment.utc(value).local().format('LLL')
                ) : (
                    <>
                        <Box>{moment.utc(value).local().format('LL')}</Box>
                        <Box> {moment.utc(value).local().format('LTS')}</Box>
                    </>
                )
            ) : (
                '-'
            )}
        </>
    )
}

export default CellDateTime
