import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'packages/eid-ui'
import clsx from 'clsx'
import config from 'config'

const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
    backgroundColor: '#307fc1',
}

const textStyles = {
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',

    maxHeight: '200px',
    minWidth: '12rem',
    overflow: 'auto',
}

export const TextFormatter = ({ attribute, item }) => {
    const { t } = useTranslation()

    var getValue = (ci, attribute) => {
        let value = ci[attribute.name]
        if (typeof value === 'boolean') {
            return (
                <>
                    {typeof value === 'boolean' && value === true ? (
                        <>{t('Yes')}</>
                    ) : (
                        <>{t('No')}</>
                    )}
                </>
            )
        } else {
            return ci[attribute.name] ?? ''
        }
    }

    let wrappedCellContent

    const cellContent = (
        <Box style={textStyles}>
            {getValue(item, attribute).length > maxCharacters
                ? `${getValue(item, attribute).substring(0, maxCharacters)}...`
                : getValue(item, attribute)}
        </Box>
    )

    wrappedCellContent =
        getValue(item, attribute).length > maxCharacters ? (
            <Tooltip
                title={getValue(item, attribute)}
                color="#5D6870"
                fontColor="#ffffff"
                enterDelay={500}
                enterNextDelay={500}
                disableInteractive={false}
                tooltipStyles={tooltipStyles}
                placement="bottom-start"
                arrow={false}
            >
                {cellContent}
            </Tooltip>
        ) : (
            cellContent
        )

    return <>{wrappedCellContent}</>
}

export const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
}

export const styles = {
    headGrey: {
        color: '#7d7c7c !important',
    },
    tableHeaderContent: {
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    green: {
        color: '#4caf50',
    },
    black: {
        color: '#424242',
    },
    blue: {
        color: colors.primary,
    },
    boldBlue: {
        color: colors.primary,
        fontWeight: 'bold',
    },
    underline: {
        textDecoration: 'underline',
    },
    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',
        '& svg': {
            color: `${colors.grey} !important`,
        },
    },
    greyColor: {
        color: 'grey !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    highlightedRow: {
        border: ' solid 1px  #307fc1',
        boxShadow: '4px 4px 15px 0 rgba(48, 127, 193, 0.15)',
    },

    tableCell: {
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 25px',
        },
    },
    tableCellHighlighted: {
        borderTop: ' solid 1px  #307fc1',
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 25px',
        },
    },
    hilightFont: {
        fontSize: '15px',
    },
    tableHeaderCell: {
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
    },
    rowSelect: {
        borderBottom: '1px solid grey',
        borderRadius: '0',
    },
    tablePagination: {
        color: 'grey !important',
    },
    bigAvatar: {
        marginLeft: '4px',
        width: 50,
        height: 50,
    },
    loadButton: {
        color: colors.primary,
        width: '100%',
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRegular: {
        width: '20px',
        height: '20px',
        objectFit: 'contain',
    },
    iconSpacing: {
        marginRight: '7px',
    },
}

export const useSortLabelClasses = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
            marginBottom: '2px !important',
        },
        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})
export const renderIcon = (heading, classes) => {
    return (
        heading.icons && (
            <span className={classes.blue}>
                {heading.icons.map((i, index) => (
                    <img
                        className={clsx(
                            classes.iconRegular,
                            classes.iconSpacing,
                        )}
                        key={`icon-image-${heading.name}-${index}`}
                        src={`${config.APP_SUBPATH}${i}`}
                        alt={''}
                    />
                ))}
            </span>
        )
    )
}
